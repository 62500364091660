import { IMenuOption } from './project-settings-menu';
import {
  PluginNames,
  ProjectSettingsType,
  UserRoles,
} from '../../shared/constants';
import {
  BarChartOutlined,
  BulbOutlined,
  CommentOutlined,
  DatabaseOutlined,
  FormOutlined,
  PieChartOutlined,
  ReadOutlined,
  SettingOutlined,
  SlidersOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { SiTwilio } from 'react-icons/si';
import IPlugin from '../../types/IPlugin';
import IProjectPlugin from '../../types/IProjectPlugin';

export interface IProjectSettingsOptions {
  label: string;
  value: ProjectSettingsType;
  key: ProjectSettingsType;
  icon: any;
}

export const projectSettingDefaultOptions: IProjectSettingsOptions[] = [
  {
    label: 'Knowledge',
    value: ProjectSettingsType.KNOWLEDGE,
    key: ProjectSettingsType.KNOWLEDGE,
    icon: ReadOutlined,
  },
  {
    label: 'Insights',
    value: ProjectSettingsType.INSIGHTS,
    key: ProjectSettingsType.INSIGHTS,
    icon: BulbOutlined,
  },
  {
    label: 'Conversations',
    value: ProjectSettingsType.CONVERSATIONS,
    key: ProjectSettingsType.CONVERSATIONS,
    icon: CommentOutlined,
  },
  {
    label: 'Plugins',
    value: ProjectSettingsType.PLUGINS,
    key: ProjectSettingsType.PLUGINS,
    icon: SlidersOutlined,
  },
  {
    label: 'Prompt',
    value: ProjectSettingsType.PROMPT,
    key: ProjectSettingsType.PROMPT,
    icon: FormOutlined,
  },
  {
    label: 'Settings',
    value: ProjectSettingsType.SETTINGS,
    key: ProjectSettingsType.SETTINGS,
    icon: SettingOutlined,
  },
  {
    label: 'Usage',
    value: ProjectSettingsType.USAGE,
    key: ProjectSettingsType.USAGE,
    icon: PieChartOutlined,
  },
  {
    label: 'Analytics',
    value: ProjectSettingsType.ANALYTICS,
    key: ProjectSettingsType.ANALYTICS,
    icon: BarChartOutlined,
  },
  {
    label: 'Twilio Outbound',
    value: ProjectSettingsType.TWILIO_OUTBOUND,
    key: ProjectSettingsType.TWILIO_OUTBOUND,
    icon: SiTwilio,
  },
];

const adminOptions = [
  {
    label: 'Tables',
    value: ProjectSettingsType.TABLES,
    key: ProjectSettingsType.TABLES,
    icon: TableOutlined,
  },
];

const recordsOption: IProjectSettingsOptions = {
  label: 'Records',
  value: ProjectSettingsType.RECORDS,
  key: ProjectSettingsType.RECORDS,
  icon: DatabaseOutlined,
};

const domainTenantMemberOptions: IProjectSettingsOptions[] = [
  {
    label: 'Conversations',
    value: ProjectSettingsType.CONVERSATIONS,
    key: ProjectSettingsType.CONVERSATIONS,
    icon: CommentOutlined,
  },
  {
    label: 'Analytics',
    value: ProjectSettingsType.ANALYTICS,
    key: ProjectSettingsType.ANALYTICS,
    icon: BarChartOutlined,
  },
];

const transformOptions = (
  options: IMenuOption[],
  convsUnreadCount: number
): IMenuOption[] => {
  return options.map((option) => {
    if (option.value === ProjectSettingsType.CONVERSATIONS) {
      return {
        ...option,
        label: `Conversations ${
          convsUnreadCount ? `(${convsUnreadCount})` : ''
        }`,
      };
    }
    return option;
  });
};

export const findPlugin = (name: string, plugins: IPlugin[]) => {
  return plugins.find((plugin) => plugin.name === name);
};

export const findInstalledProjectPlugin = (
  plugin: IPlugin | undefined,
  projectPlugins: IProjectPlugin[]
) => {
  return projectPlugins.find(
    (projectPlugin) => projectPlugin.pluginId === plugin?.id
  );
};

export const getProjectSettingsOptions = (
  plugins: IPlugin[],
  projectPlugins: IProjectPlugin[] | undefined,
  convsUnreadCount: number,
  isDomainTenantMember: boolean,
  userRole: UserRoles | null
): IProjectSettingsOptions[] => {
  if (isDomainTenantMember) {
    const defOptions = transformOptions(
      domainTenantMemberOptions,
      convsUnreadCount
    );
    if (projectPlugins && plugins.length > 0) {
      const recordsPlugin = findPlugin(PluginNames.RECORDS, plugins);
      const adfEmailPlugin = findPlugin(PluginNames.ADF_PLUGIN, plugins);

      if (!recordsPlugin && !adfEmailPlugin) return defOptions;
      const recordsOrAdfEmailPluginInstalled =
        findInstalledProjectPlugin(recordsPlugin, projectPlugins) ||
        findInstalledProjectPlugin(adfEmailPlugin, projectPlugins);
      if (!recordsOrAdfEmailPluginInstalled) {
        return defOptions;
      }

      const options = [
        ...defOptions.slice(0, 1),
        recordsOption,
        ...defOptions.slice(1),
      ];

      return options;
    }

    return defOptions;
  }

  const defOptions = transformOptions(
    projectSettingDefaultOptions,
    convsUnreadCount
  );

  if (userRole === UserRoles.ADMIN) {
    defOptions.push(...adminOptions);
  }

  if (projectPlugins && plugins.length > 0) {
    const recordsPlugin = findPlugin(PluginNames.RECORDS, plugins);
    const adfEmailPlugin = findPlugin(PluginNames.ADF_PLUGIN, plugins);

    if (!recordsPlugin && !adfEmailPlugin) return defOptions;
    const recordsOrAdfEmailPluginInstalled =
      findInstalledProjectPlugin(recordsPlugin, projectPlugins) ||
      findInstalledProjectPlugin(adfEmailPlugin, projectPlugins);
    if (!recordsOrAdfEmailPluginInstalled) {
      return defOptions;
    }

    const options = [
      ...defOptions.slice(0, 3),
      recordsOption,
      ...defOptions.slice(3),
    ];

    return options;
  }

  return defOptions;
};
