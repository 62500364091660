import { getDomain } from './helpers';

export const BASE_URL_LOCAL = 'http://localhost:9000';
export const BASE_URL_PROD = `https://api.${getDomain()}`;

export const WIDGET_BASE_URL_LOCAL = 'http://localhost:3001';
export const WIDGET_BASE_URL_PROD = `https://widget.${getDomain()}`;
// export const WIDGET_BASE_URL_PROD = 'https://widget.zappr.ai';

export const WIDGET_SCRIPT_URL_PROD = `https://static.${getDomain()}/static/scripts/embed.min.js`;
export const WIDGET_SCRIPT_URL_LOCAL =
  'https://static.zappr.ai/static/tmp/__widget.min.js';
// %PUBLIC_URL%/for_test/widget.js

export const LANDING_URL = 'https://zappr.ai';
export const DASHBOARD_DEFAULT_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? // ? 'dashboard.localhost' + 'asd'
      'dashboard.localhost'
    : 'dashboard.zappr.ai';

export const DEFAULT_DOMAIN =
  process.env.NODE_ENV === 'development' ? 'localhost' : 'zappr.ai';

export const DEFAULT_ZAPPR_DOMAIN = 'zappr.ai';

export const SDK_NAME = process.env.REACT_APP_SDK_NAME || '';
export const ON_SDK_LOADED = `${SDK_NAME}Loaded`;

//TODO: move to local storage service
//Local Storage
export const LS_TOKEN_KEY = `__${getDomain()}_t`;
export const LS_DEVICE_ID = `__${getDomain()}_d_id`;
export const LS_LANDING_CHAT_USER_ID = `__${getDomain()}_l_c_user_id`;
export const LS_USER_USAGE_INFO = `__${getDomain()}_u_u_i_l`;
export const LS_USER_USAGE_INFO_UPDATED_AT = `__${getDomain()}_u_u_i_u_a`;
export const LS_CURRENT_ORGANIZATION_ID = `__${getDomain()}_c_o_id`;

// used in embed widget script
export const LS_WIDGET_CLOSED_INFO = `__${getDomain()}_w_c_i`;

//Landing Demo
export const DEMO_START_CHAT_MESSAGE = 'Hi! How can I help you?';
//TODO: update demo project id
export const DEMO_PROJECT_ID_LANDING_PROD = 'SReEEzlOBEyJb--ChGPD-A';
export const DEMO_PROJECT_ID_LANDING_LOCAL = 'tm0HMvrEKEaU9GfJvBtuhw';
export const DEMO_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiWUR5ZG13ZlFGNThGcXM5RkJ5Q2ZSTSIsImV4cCI6MTY4MDMzOTE3MH0.PMxX3rdM5VfYYCqmw2M1z9WostioFmkVuMgNruwkbBk';
// export const DEMO_PROJECT_ID_LANDING = '9DvRYDdDxWcGxgqzaPGVyE'; //askbar constitution

// Validation messages
export const V_REQUIRED_FIELD = 'This field is required.';
export const V_PASSWORD_MIN_LENGTH =
  ' Passwords must be at least 6 characters.';
export const V_PASSWORD_LOWER_UPPER_DIGIT =
  " Passwords must contain at least one uppercase letter ('A'-'Z'), one lowercase letter ('a'-'z') and one digit ('0'-'9').";

export const regexpLowerUpperDigit = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
export const regexpOrganizationName = /^[a-zA-Z0-9\-_ ]+$/;

export const regexpPositiveIntOrZero = /^[0-9]\d*$/;

export const X_ORG_ID_HEADER = 'x-tenant-id';

export enum Subdomains {
  DASHBOARD = 'dashboard',
}

export enum BotTypes {
  CHATBOT = 'chat',
  ASKBAR = 'ask',
}

export enum LogoMode {
  LIGHT = 'light',
  DARK = 'dark',
}
export enum EngineTypes {
  GPT3 = 'gpt3',
  GPT4 = 'gpt4',
  GPT4Turbo = 'gpt4Turbo',
  GPT4o = 'gpt4o',
}

export enum ProjectStatus {
  READY = 'ready',
  CLONING = 'cloning',
}

export enum MessageRoles {
  USER = 'user',
  SYSTEM = 'system',
  ASSISTANT = 'assistant',
  FUNCTION = 'function',
}

export enum KnowledgeDataSources {
  FILE = 'file',
  WEB = 'web',
  TEXT = 'text',
  GITHUB = 'gitHub',
  ZENDESK = 'zendesk',
  SITEMAP = 'sitemap',
  SITE = 'site',
  FAMA = 'fama',
  QA = 'qnA',
  TABLE = 'table',
  YOUTUBE = 'youtubeUrl',
}

export enum KnowledgeDataSourcesFileTypes {
  TXT = 'txt',
  PDF = 'pdf',
  // DOC = 'doc',
  DOCX = 'docx',
  MD = 'md',
  CSV = 'csv',
  // PPTX = 'pptx',
  // RTF = 'rtf',
  // XLSX = 'xlsx',
}

export enum SubscriptionPlanTypes {
  FREE = 'free',
  STARTER = 'starter',
  STANDARD = 'standard',
  PREMIUM = 'premium',
  ENTERPRISE = 'enterprise',
}

export enum BillingPlansTypes {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum KnowledgeStatus {
  UNSAVED = 'unsaved',
  PENDING = 'pending',
  PROCESSING = 'processing',
  READY = 'ready',
  ERROR = 'error',
  SKIPPED = 'skipped',
}

export enum GABotSharingTypes {
  COPY_LINK = 'copy_link',
}

export enum GASendBotMessageSources {
  DEMO = 'demo',
  WIDGET = 'widget',
}

export enum EmbeddingsStyle {
  RAW = 'raw',
  QA = 'qa',
}

export enum ConversationMessageTypes {
  TEXT = 'text',
  COMMAND = 'command',
  ERROR = 'error',
  HANDSOFF = 'handsoff',
  HANDSON = 'handson',
  OPTOUT = 'optout',
}

export enum ErrorCodes {
  EMAIL_NOT_CONFIRMED = 'email_not_confirmed',
}

export enum ProfileSettingsType {
  ACCOUNT = 'account',
  PASSWORD = 'password',
  USAGE = 'usage',
  PLAN = 'plan',
  BUY_CREDITS = 'buy-credits',
  API_KEY = 'api-key',
  ORGANIZATION_TITLE = 'organization-title',
  ORGANIZATION = 'organization',
  ADD_NEW_ORGANIZATION = 'add-new-organization',
  DOMAINS = 'domains',
}

export enum ProjectSettingsType {
  KNOWLEDGE = 'knowledge',
  INSIGHTS = 'insights',
  CONVERSATIONS = 'conversations',
  PLUGINS = 'plugins',
  PROMPT = 'prompt',
  SETTINGS = 'settings',
  ADVANCED = 'advanced',
  USAGE = 'usage',
  RECORDS = 'records',
  TWILIO_OUTBOUND = 'twilio-outbound',
  ANALYTICS = 'analytics',
  TABLES = 'tables',
}

export enum SettingsTabsTypes {
  BASIC = 'basic',
  APPEARANCE = 'appearance',
  ADVANCED = 'advanced',
  ADVANCED_LLM_SETTINGS = 'advanced-llm-settings',
}

export enum OrganizationSettingsType {
  MEMBERS = 'members',
  USAGE = 'usage',
  SETTINGS = 'settings',
  INTEGRATIONS = 'integrations',
  BRANDING = 'branding',
}

export enum ProjectInsightsType {
  POORLY_RATED = 'poorly-rated',
  NO_SOURCES_FOUND = 'no-sources-found',
  WELL_RATED = 'well-rated',
  TAUGHT_QUESTIONS = 'taught-questions',
}

export enum SettingsSubTabsType {
  BASIC = 'basic',
  APPEARANCE = 'appearance',
  ADVANCED = 'advanced',
  ADVANCED_LLM_SETTINGS = 'advanced-llm-settings',
}

export enum TablesSubTabsType {
  CREATE = 'create',
}

export enum MessageRatingTypes {
  NOT_RATED = 'not_rated',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export enum ProjectPluginStatus {
  UNKNOWN = 'unknown',
  STARTING = 'starting',
  RUNNING = 'running',
  ERROR = 'error',
}

export enum PluginPropTypes {
  AUTH = 'auth',
  GOOGLE_AUTH = 'googleAuth',
  FACEBOOK_AUTH = 'facebookAuth',
  STRING = 'string',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  FLOAT = 'float',
  DATETIME = 'datetime',
  TIME = 'time',
  PHONE = 'phone',
  EMAIL = 'email',
  URL = 'url',
  ENUM = 'enum',
  OBJECT = 'object',
  ARRAY = 'array',
  FILE = 'file',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  LOCATION = 'location',
  COLOR = 'color',
  PASSWORD = 'password',
  MARKDOWN = 'markdown',
  PERCENTAGE = 'percentage',
  MULTILINE = 'multiline',
  KEY_VALUE_ARRAY = 'keyValueArray',
}

export enum PluginNames {
  FACEBOOK_MESSENGER = 'meta_messenger',
  RECORDS = 'records',
  HTTP_PLUGIN = 'http_plugin',
  ADF_PLUGIN = 'adf_email',
  TWILIO = 'twilio',
  JIRA = 'jira',
}

export enum PluginValidators {
  URL = 'url',
  EMAIL = 'email',
  FILE = 'file',
  FEW_EMAILS = 'few_emails',
}

export enum UsageTypes {
  PROJECTS = 'projects',
  MESSAGES = 'messages',
  CHARACTERS = 'chars',
}

export enum DataExposureLevel {
  SAFE = 'safe',
  LOW = 'low',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export enum OrganizationMemberRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
  CLIENT = 'client',
}

export enum UserRoles {
  ADMIN = 'ADMINISTRATOR',
  CLIENT = 'CLIENT',
}

export enum TableRecordStatus {
  NEW = 'new',
  READ = 'read',
}

export enum WhatsAppOutboundStatus {
  RUNNING = 'running',
  CANCELED = 'canceled',
  READY = 'ready',
  FINISHED = 'finished',
}

export enum ConversationChannelId {
  WHATS_APP = 'whatsapp',
  SMS = 'sms',
}

export enum TableImportOptions {
  OVERWRITE = 'overwrite',
  MERGE = 'merge',
}

export enum TableColumnTypes {
  INTEGER = 'integer',
  STRING = 'string',
}

export const SELECT_ROW_KEY = 'select-row';

export enum CreateProjectDescriptionModalTypes {
  ROLES = 'roles',
  PERSONALITIES = 'personalities',
}

export enum WidgetStreamModes {
  DEFAULT = 'default',
  TYPING = 'typing',
  OFF = 'off',
}

export enum WidgetVersions {
  V1 = 'v1',
  V2 = 'v2',
}

export enum WidgetThemeModes {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum WidgetMessageStyles {
  BRIGHT = 'bright',
  DIMMED = 'dimmed',
}

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

export const widgetTypeOptions = [
  { label: 'Chat Bot', value: BotTypes.CHATBOT },
  { label: 'Ask Bar', value: BotTypes.ASKBAR },
];

export const widgetThemeOptions = [
  { label: 'Dark', value: WidgetThemeModes.DARK },
  { label: 'Light', value: WidgetThemeModes.LIGHT },
];

export const widgetMessageStyleOptions = [
  { label: 'Bright', value: WidgetMessageStyles.BRIGHT },
  { label: 'Dimmed', value: WidgetMessageStyles.DIMMED },
];

export const widgetColorOptions = [
  { label: 'Acid', value: '#6AEC04' },
  { label: 'Azure', value: '#04C2EC' },
  { label: 'Blue', value: '#0062FF' },
  { label: 'Dark Blue', value: '#4E4FEB' },
  { label: 'Emerald', value: '#3CAEA3' },
  { label: 'Light Blue', value: '#0A84FF' },
  { label: 'Purple', value: '#9F0D7F' },
  { label: 'Red', value: '#DB005B' },
  { label: 'Violet', value: '#A155B9' },
  { label: 'Yellow', value: '#ECB404' },
  { label: 'Charcoal Grey', value: '#36454F' },
];
