import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  LS_CURRENT_ORGANIZATION_ID,
  LS_TOKEN_KEY,
  X_ORG_ID_HEADER,
} from '../shared/constants';
import { config } from '../shared/config';
import { RootState } from '../store/store';
import { getDomain } from '../shared/helpers';

export enum APITagTypes {
  USER = 'user',
  USER_PROJECTS = 'userProjects',
  PLANS = 'plans',
  CURRENT_USER_SUBSCRIPTION = 'currentUserSubscription',
  SUBSCRIPTION_IS_ACTIVE = 'subscriptionIsActive',
  USER_USAGE_INFO = 'userUsageInfo',
  USER_USAGE = 'userUsage',
  PLUGINS = 'plugins',
  PROJECT_PLUGINS = 'projectPlugins',
  PROJECT_INSIGHTS = 'projectInsights',
  PROJECT_TABLES = 'projectTables',
  ORGANIZATIONS = 'organizations',
  INSIGHTS = 'insights',
  WHATS_APP_OUTBOUND_SETTINGS = 'whatsAppOutboundSettings',
  WHATS_APP_OUTBOUND_CAMPAIGNS = 'whatsAppOutboundCampaigns',
  DOMAINS = 'domains',
}

const getLocalUrl = () => {
  const hostname = window.location.hostname;
  if (hostname === 'localhost' || hostname === 'dashboard.localhost') {
    return config.API;
  }

  return 'http://' + hostname.replace('dashboard.', 'api.') + ':9000';
};

export const getBaseUrl =
  process.env.NODE_ENV === 'development'
    ? getLocalUrl() + '/v1'
    : 'https://api.' + getDomain() + '/v1';

export const setHeaders = (headers: Headers, state: RootState) => {
  // console.log(new Map(headers));
  const token = localStorage.getItem(LS_TOKEN_KEY);
  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }

  const domain = getDomain();
  headers.set('x-domain', domain);

  const hasOrgHeader = headers.has(X_ORG_ID_HEADER);

  const organizationId =
    state.organizationsReducer.currentOrganization?.id ||
    localStorage.getItem(LS_CURRENT_ORGANIZATION_ID);
  if (organizationId !== null && !hasOrgHeader) {
    headers.set(X_ORG_ID_HEADER, organizationId);
  }

  return headers;
};

export const apiService = createApi({
  reducerPath: 'apiService',
  baseQuery: fetchBaseQuery({
    // baseUrl: config.API + '/v1',
    baseUrl: getBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      setHeaders(headers, getState() as RootState);
    },
  }),
  tagTypes: [
    APITagTypes.USER,
    APITagTypes.USER_PROJECTS,
    APITagTypes.PLANS,
    APITagTypes.CURRENT_USER_SUBSCRIPTION,
    APITagTypes.SUBSCRIPTION_IS_ACTIVE,
    APITagTypes.USER_USAGE_INFO,
    APITagTypes.USER_USAGE,
    APITagTypes.PLUGINS,
    APITagTypes.PROJECT_PLUGINS,
    APITagTypes.PROJECT_INSIGHTS,
    APITagTypes.PROJECT_TABLES,
    APITagTypes.WHATS_APP_OUTBOUND_SETTINGS,
    APITagTypes.WHATS_APP_OUTBOUND_CAMPAIGNS,
    APITagTypes.ORGANIZATIONS,
    APITagTypes.DOMAINS,
  ],
  endpoints: () => ({}),
});
