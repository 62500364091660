import React, { useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import { RouteNames } from '../../router/router';
import {
  ProjectInsightsType,
  ProjectSettingsType,
  SettingsSubTabsType,
  TablesSubTabsType,
} from '../../shared/constants';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { projectsAPI } from '../../services/projects-service';

interface BreadcrumbItem {
  key: string;
  title: string | JSX.Element;
}

interface BreadcrumbSeparator {
  type: string;
  separator?: string;
}

const BreadcrumbNav = () => {
  const { orgId, id } = useParams();
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const breadcrumbNameMapDefault: Record<string, string> = {
    [RouteNames.USER_PROJECTS]: 'Projects',
    [RouteNames.CREATE_PROJECT_TAB_NAME]: 'Create New',
    // [`/${PersonalOrganizationType}`]: 'Personal',
    // [RouteNames.PROJECT_SETTINGS_TAB_NAME]: 'Project Settings',
    [`/${ProjectSettingsType.KNOWLEDGE}`]: 'Knowledge',
    [`/${ProjectSettingsType.INSIGHTS}`]: 'Insights',
    [`/${ProjectSettingsType.CONVERSATIONS}`]: 'Conversations',
    [`/${ProjectSettingsType.RECORDS}`]: 'Records',
    [`/${ProjectSettingsType.PLUGINS}`]: 'Plugins',
    [`/${ProjectSettingsType.PROMPT}`]: 'Prompt',
    [`/${ProjectSettingsType.SETTINGS}`]: 'Settings',
    [`/${ProjectSettingsType.ADVANCED}`]: 'Advanced',
    [`/${ProjectSettingsType.USAGE}`]: 'Usage',
    [`/${ProjectSettingsType.TWILIO_OUTBOUND}`]: 'Twilio Outbound',
    [`/${ProjectSettingsType.ANALYTICS}`]: 'Analytics',
    [`/${ProjectSettingsType.TABLES}`]: 'Tables',
    [`/${ProjectInsightsType.POORLY_RATED}`]: 'Insights - Poorly Rated',
    [`/${ProjectInsightsType.WELL_RATED}`]: 'Insights - Well Rated',
    [`/${ProjectInsightsType.TAUGHT_QUESTIONS}`]: 'Insights - Taught Questions',
    [`/${SettingsSubTabsType.BASIC}`]: 'Settings - Basic',
    [`/${SettingsSubTabsType.APPEARANCE}`]: 'Settings - Appearance',
    [`/${SettingsSubTabsType.ADVANCED}`]: 'Settings - Advanced',
    [`/${SettingsSubTabsType.ADVANCED_LLM_SETTINGS}`]:
      'Settings - Advanced LLM Settings',
    // [`/${TablesSubTabsType.CREATE}`]: 'Create',
    [RouteNames.DEMO_TAB_NAME]: 'Demo',
  };

  const [breadcrumbItems, setBreadcrumbItems] = React.useState<
    (BreadcrumbItem | BreadcrumbSeparator)[]
  >([]);

  const [
    lazyGetUserProjectById,
    {
      data: project,
      error: lazyProjectError,
      isLoading: lazyUserProjectLoading,
    },
  ] = projectsAPI.useLazyGetUserProjectByIdQuery();

  useEffect(() => {
    if (id) {
      lazyGetUserProjectById({ id, orgId });
    }
  }, [id, orgId]);

  useEffect(() => {
    setBreadcrumbItems(createBreadcrumbItems(breadcrumbNameMapDefault));
  }, [project, location.pathname]);

  // const { allOrganizationsInfo } = useAppSelector(
  //   (state) => state.organizationsReducer
  // );
  //
  // useEffect(() => {
  //   const breadcrumbNameMapOrgs = allOrganizationsInfo.reduce((acc, org) => {
  //     const key = `/${org.id}`;
  //     acc[key] = org.name;
  //     return acc;
  //   }, {} as Record<string, string>);
  //
  //   setBreadcrumbItems(
  //     createBreadcrumbItems({
  //       ...breadcrumbNameMapDefault,
  //       ...breadcrumbNameMapOrgs,
  //     })
  //   );
  // }, [allOrganizationsInfo]);

  // typescript flatMap error fix
  function returnBreadcrumbElement(
    item: BreadcrumbItem | BreadcrumbSeparator
  ): BreadcrumbItem | BreadcrumbSeparator {
    return item;
  }

  // TODO: should do smth with it
  function createBreadcrumbItems(
    breadcrumbNameMap: Record<string, string>
  ): (BreadcrumbItem | BreadcrumbSeparator)[] {
    return pathSnippets.flatMap((pathPart, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

      const title = breadcrumbNameMap['/' + pathPart] || pathPart;

      const projectsPathPart = '/' + pathPart === RouteNames.USER_PROJECTS;
      const projectSettingsPathPart =
        '/' + pathPart === RouteNames.PROJECT_SETTINGS_TAB_NAME;
      const projectInsightPathPart = pathPart === ProjectSettingsType.INSIGHTS;
      const demoPathPart = '/' + pathPart === RouteNames.DEMO_TAB_NAME;

      const projectsBrdcrmp =
        url.includes(RouteNames.USER_PROJECTS) && index === 1;
      const projectSettingsBrdcrmp =
        url.includes(RouteNames.USER_PROJECTS) &&
        url.includes(RouteNames.PROJECT_SETTINGS_TAB_NAME) &&
        index === 3;
      const projectDemoBrdcrmp =
        url.includes(RouteNames.USER_PROJECTS) &&
        url.includes(RouteNames.DEMO_TAB_NAME) &&
        index === 3;
      const conversationBrdcrmp = url.includes(
        ProjectSettingsType.CONVERSATIONS
      );
      const settingsBrdcrmp =
        url.includes(RouteNames.PROJECT_SETTINGS_TAB_NAME) &&
        url.includes(ProjectSettingsType.SETTINGS) &&
        index === 4 &&
        pathPart === ProjectSettingsType.SETTINGS;
      const isLast = index === pathSnippets.length - 1;
      const advancedBrdcrmb =
        url.includes(RouteNames.PROJECT_SETTINGS_TAB_NAME) &&
        url.includes(ProjectSettingsType.SETTINGS) &&
        pathPart === ProjectSettingsType.ADVANCED &&
        index === 4 &&
        isLast;

      const projectWAOutboundBrdcrmp =
        url.includes(ProjectSettingsType.TWILIO_OUTBOUND) && index === 5;

      const tableBrdcrmp =
        url.includes(ProjectSettingsType.TABLES) && index === 5;

      const returnTitle = (title: string, url: string, needUrl?: boolean) => {
        const withUrl = needUrl ? true : !isLast;
        return !withUrl ? title : <Link to={url}>{title}</Link>;
      };

      const defaultSeparator = {
        type: 'separator',
      };

      const customSeparator = {
        type: 'separator',
        separator: ':',
      };

      if (projectsBrdcrmp) {
        return [
          returnBreadcrumbElement({
            key: url,
            title: returnTitle('Projects', url),
          }),
          defaultSeparator,
        ];
      }

      if (projectSettingsBrdcrmp) {
        return [
          returnBreadcrumbElement({
            key: url,
            title: returnTitle(
              project ? project.name : 'Project Settings',
              url
            ),
          }),
          defaultSeparator,
        ];
      }

      if (advancedBrdcrmb) {
        return returnBreadcrumbElement({
          key: url,
          title: 'Advanced',
        });
      }

      if (projectWAOutboundBrdcrmp) {
        return returnBreadcrumbElement({
          key: url,
          title: 'Campaign',
        });
      }

      if (tableBrdcrmp) {
        return returnBreadcrumbElement({
          key: url,
          title: 'Table',
        });
      }

      if (projectDemoBrdcrmp) {
        const projectSettingsDemoItem = returnBreadcrumbElement({
          key: url + '/project-settings',
          title: returnTitle(
            project ? project.name : pathPart,
            url.replace(
              RouteNames.DEMO_TAB_NAME,
              RouteNames.PROJECT_SETTINGS_TAB_NAME
            ) +
              '/' +
              ProjectSettingsType.KNOWLEDGE,
            true
          ),
        });

        return [
          projectSettingsDemoItem,
          defaultSeparator,
          returnBreadcrumbElement({
            key: url,
            title: 'Preview',
          }),
        ];
      }

      if (conversationBrdcrmp && index === 4) {
        return [
          returnBreadcrumbElement({
            key: url,
            title: 'Conversations',
          }),
        ];
      }

      if (settingsBrdcrmp) {
        return [];
      }

      if (
        conversationBrdcrmp &&
        isLast &&
        pathPart !== ProjectSettingsType.CONVERSATIONS
      ) {
        return [];
      }

      if (index === pathSnippets.length - 1) {
        return [
          returnBreadcrumbElement({
            key: url,
            title: title,
          }),
        ];
      }

      if (
        projectsPathPart ||
        projectSettingsPathPart ||
        projectInsightPathPart ||
        demoPathPart
      ) {
        return [
          // returnBreadcrumbElement({
          //   key: url,
          //   title: title,
          // }),
          // customSeparator,
        ];
      }

      return [
        returnBreadcrumbElement({
          key: url,
          title: <Link to={url}>{title}</Link>,
        }),
        defaultSeparator,
      ];
    });
  }

  return (
    <div>
      <Breadcrumb
        separator=""
        items={breadcrumbItems as ItemType[]}
        className="breadcrumb"
      />
    </div>
  );
};

export default BreadcrumbNav;
