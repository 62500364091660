import { WidgetStreamModes } from '../../../shared/constants';
import IUpdateProjectSettings from '../../../types/IUpdateProjectSettings';
import IWidgetSettings, {
  IWidgetSpeechBubbleItem,
  IWidgetSpeechBubbles,
} from '../../../types/IWidgetSettings';

export default class SettingsHelper {
  public static getFormWidgetSettings(
    allValues: IUpdateProjectSettings,
    speechBubbleInitialValues: IWidgetSpeechBubbles
  ): IWidgetSettings {
    return {
      agentName: allValues.agentName,
      userLabel: allValues.userLabel,
      placeHolder: allValues.placeHolder,
      followPages: allValues.followPages,
      theme: allValues.theme,
      color: allValues.color,
      speechBubble:
        // @ts-ignore
        allValues.widgetSettings.speechBubble || speechBubbleInitialValues,
      hasBackgroundImage: false,
      enableMicrophone: allValues.enableMicrophone,
      enableAttachments: false,
      enableReferences: allValues.enableReferences,
      streamMode: allValues.streamMode || WidgetStreamModes.DEFAULT,
      title: allValues.title,
    };
  }

  public static checkSpeechBubbleItemsChanged(
    formValue: IWidgetSpeechBubbles,
    widgetValue: IWidgetSpeechBubbles
  ) {
    if (formValue.isEnabled !== widgetValue.isEnabled) {
      return true;
    }
    if (
      formValue.items &&
      widgetValue.items &&
      formValue.items.length !== widgetValue.items.length
    ) {
      return true;
    }
    if (
      formValue.items &&
      widgetValue.items &&
      formValue.items.length &&
      widgetValue.items.length
    ) {
      for (let i = 0; i < formValue.items.length; i++) {
        const formItem = formValue.items[i];
        const widgetItem = widgetValue.items[i];
        if (widgetItem) {
          for (const key in formItem) {
            if (
              formItem[key as keyof IWidgetSpeechBubbleItem] !==
              widgetItem[key as keyof IWidgetSpeechBubbleItem]
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }
}
