import React, { FC } from 'react';
import { Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import IConversationMessage, {
  IConversationMessageItem,
} from '../../../types/IConversationMessage';
import {
  getConversationMessageItemText,
  getFormattedMessageDate,
} from './conversation-helpers';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { UserRoles } from '../../../shared/constants';

interface IConversationUserMessageProps {
  message: IConversationMessage;
  openTrace: ((messageId: string) => void) | null;
}

const ConversationUserMessage: FC<IConversationUserMessageProps> = ({
  message,
  openTrace,
}) => {
  return (
    <>
      {message.items.map((item, index) => {
        return (
          <ConversationUserMessageItem
            key={index}
            id={message.id}
            messageItem={item}
            openTrace={openTrace}
            createAt={message.createAt}
          />
        );
      })}
    </>
  );
};

interface IConversationUserMessageItemProps {
  id: string;
  messageItem: IConversationMessageItem;
  openTrace: ((messageId: string) => void) | null;
  createAt: string;
}

const ConversationUserMessageItem: FC<IConversationUserMessageItemProps> = ({
  id,
  messageItem,
  openTrace,
  createAt,
}) => {
  const { userRole } = useAppSelector((state) => state.userReducer);

  return (
    <div className="insight-user-message-container">
      <div
        className={'conversation-message-container'}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}>
          {openTrace && userRole && userRole === UserRoles.ADMIN && (
            <Button onClick={() => openTrace(id)}>Trace</Button>
          )}
          <div className="insight-user-message conversation-message-text">
            {getConversationMessageItemText(messageItem)}
          </div>
        </div>
        <div
          className={
            'insight-date conversation-message-date-time conversation-message-date-time--user'
          }
        >
          {getFormattedMessageDate(createAt)}
        </div>
      </div>
      <Avatar size={40} icon={<UserOutlined />} className={'insight-avatar'} />
    </div>
  );
};

export default ConversationUserMessage;
