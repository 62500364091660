import React, { FC, useEffect, useState } from 'react';
import { IConversationItem } from '../project-settings/conversations/conversation-messages';
import IConversationMessage from '../../types/IConversationMessage';
import ConversationAiMessage from '../project-settings/conversations/conversation-ai-message';
import { Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
  getConversationMessageItemText,
  getFormattedMessageDate,
} from '../project-settings/conversations/conversation-helpers';
import { formatDateFromISO8601 } from '../../shared/helpers';
import { UserRoles } from '../../shared/constants';
import ConversationUserMessage from '../project-settings/conversations/conversation-user-message';

interface IShareConversationMessagesProps {
  conversationMessages: IConversationMessage[];
}

const ShareConversationMessages: FC<IShareConversationMessagesProps> = ({
  conversationMessages,
}) => {
  const [conversationItems, setConversationItems] = useState<
    IConversationItem[]
  >([]);

  useEffect(() => {
    const transformedArray = [];
    let currentGroup: IConversationItem = { userMsg: null, botMsgs: [] };

    for (let i = 0; i < conversationMessages.length; i++) {
      const msg = conversationMessages[i];
      if (msg.isUserSender) {
        transformedArray.push(currentGroup);
        currentGroup = { userMsg: { ...msg, index: i }, botMsgs: [] };
      } else {
        currentGroup.botMsgs.push({ ...msg, index: i });
      }
    }

    transformedArray.push(currentGroup);

    setConversationItems(transformedArray);
  }, [conversationMessages]);

  return (
    <>
      {conversationMessages.length > 0 && (
        <div className="conversation-header">
          <div className="insight-date conversation-msg-date conversation-header-item">
            {formatDateFromISO8601(conversationMessages[0].createAt) + ' '}
            (Messages: {conversationMessages.length})
          </div>
        </div>
      )}
      {conversationItems.map((item, index) => (
        <div className="conversation-msg-container" key={index}>
          {item.userMsg && (
            <ConversationUserMessage message={item.userMsg} openTrace={null} />
          )}
          {
            <ConversationAiMessage
              messages={item.botMsgs}
              handleTeachModel={() => {}}
              shareMode={true}
            />
          }
        </div>
      ))}
    </>
  );
};

export default ShareConversationMessages;
