import React, { FC } from 'react';
import { Spin } from 'antd';
import './loading.scss';

const Loading: FC<{ height?: string }> = ({ height }) => {
  return (
    <div className="container loading" style={{ height }}>
      <Spin size="large" tip="Loading..." className="loading__spin" />
    </div>
  );
};

export default Loading;
