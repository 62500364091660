import { FC, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import './plugins.scss';
import { Alert, Button, Form, Input, message, Tooltip } from 'antd';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { pluginsAPI } from '../../../services/plugins-service';
import { SettingOutlined, WarningOutlined } from '@ant-design/icons';
import { getAllPluginProps, showErrorMessage } from '../../../shared/helpers';
import {
  DataExposureLevel,
  PluginNames,
  PluginPropTypes,
  ProjectPluginStatus,
} from '../../../shared/constants';
import IProjectPluginProperties from '../../../types/IProjectPluginProperties';
import Loading from '../../loading/loading';
import GoogleLoginButton from '../../google-login-button/google-login-button';
import IPluginItem from '../../../types/IPluginItem';
import FbPluginForm from './fb-plugin/fb-plugin-form';
import { MessageInstance } from 'antd/es/message/interface';
import ArrayInputField from './input-fields/array-input-field';
import IPluginProperties from '../../../types/IPluginProperties';
import BooleanFormItem from './input-fields/boolean-form-item';
import MultilineFormItem from './input-fields/multiline-form-item';
import PasswordFormItem from './input-fields/password-form-item';
import NumberFormItem from './input-fields/number-form-item';
import PercentageFormItem from './input-fields/percentage-form-item';
import DefaultFormItem from './input-fields/default-form-item';
import TimeFormItem from './input-fields/time-form-item';
import InputFactory from './input-fields/input-factory';
import StorageUtils from '../../../shared/utils/storage-utils';

const Plugins: FC<{ projectId: string }> = ({ projectId }) => {
  const { orgId } = useParams();
  const { currentOrganizationLoading } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const { plugins } = useAppSelector((state) => state.pluginsReducer);
  const { currentUserSubscription } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const [skip, setSkip] = useState(true);
  const { error: pluginsError } = pluginsAPI.useGetPluginsQuery();
  const { data: projectPlugins, error: projectPluginsError } =
    pluginsAPI.useGetProjectPluginsQuery({ id: projectId, orgId }, { skip });
  const [requestDemo, { isLoading: requestDemoLoading, error }] =
    pluginsAPI.useRequestDemoPluginMutation();
  const [pluginGoogleLogin, {}] = pluginsAPI.usePluginGoogleLoginMutation();
  const [installPlugin, {}] = pluginsAPI.useInstallPluginMutation();
  const [uninstallPlugin, {}] = pluginsAPI.useUninstallPluginMutation();
  const [updatePluginSettings, {}] =
    pluginsAPI.useUpdatePluginSettingsMutation();
  const [logoutGetJobberPlugin, { isLoading: logoutGetJobberLoading }] =
    pluginsAPI.useLogoutGetJobberPluginMutation();
  const [loginGetJobberPlugin, {}] =
    pluginsAPI.useLoginGetJobberPluginMutation();
  const [lazyGetProjectPluginsQuery, { data: lazyGetProjectPluginsData }] =
    pluginsAPI.useLazyGetProjectPluginsQuery();
  const [openedPluginSettings, setOpenedPluginSettings] = useState<string[]>(
    []
  );
  const [pluginsList, setPluginsList] = useState<IPluginItem[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [jobberSaveDisabled, setJobberSaveDisabled] = useState<boolean>(true);
  const [googleSheetsDisabled, setGoogleSheetsSaveDisabled] =
    useState<boolean>(true);
  const [form] = Form.useForm();

  // disabled plugin setting bug fix
  // contains plugin ids (not pluginId)
  const [disabledPluginSettings, setDisabledPluginSettings] = useState<
    string[]
  >([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const getJobberAuthCallbackIndicator = 'from-get-jobber';
  const [isGetJobberAuthCallback, setIsGetJobberAuthCallback] = useState(() => {
    return searchParams.get(getJobberAuthCallbackIndicator) !== null;
  });
  const [loginGetJobberDisabled, setLoginGetJobberDisabled] = useState(false);
  const [getJobberValue, setGetJobberValue] = useState<string>('');
  const [googleAccountValue, setGoogleAccountValue] = useState<string>('');

  useEffect(() => {
    if (isGetJobberAuthCallback) {
      const pluginId = searchParams.get(getJobberAuthCallbackIndicator);
      if (!pluginId) return;

      const f = async () => {
        const pluginData = pluginsList.find((p) => p.id === pluginId);
        if (!pluginData) return;
        // console.log('f here');
        setLoginGetJobberDisabled(true);
        const code = searchParams.get('code') || '';
        const result = await loginGetJobberPlugin({
          projectId,
          pluginId: pluginData.id,
          projectPluginId: pluginData.pluginId,
          code,
        });

        let r = new URL(window.location.href);
        r.searchParams.delete(getJobberAuthCallbackIndicator);
        r.searchParams.delete('code');
        r.searchParams.delete('state');
        const newUrl = r.href;
        window.history.pushState({ path: newUrl }, '', newUrl);

        setIsGetJobberAuthCallback(false);

        if ('error' in result) {
          await messageApi.error(`Authentication error.`);
        } else {
          setGetJobberValue(result.data.name);
          setOpenedPluginSettings([...openedPluginSettings, pluginId]);
          const propValues = {
            ...pluginData.properties,
            account: result.data.name,
          };
          handleChangePluginSettings(propValues, pluginData.id);

          let hasEmptyRequiredFields = false;
          for (let key in pluginData.allProperties) {
            const typedKey = key as keyof typeof pluginData.allProperties;
            try {
              if (
                (pluginData.allProperties[typedKey] as IPluginProperties)
                  .isRequired &&
                !propValues[typedKey]
              ) {
                hasEmptyRequiredFields = true;
                break;
              }
            } catch (e) {}
          }

          if (!hasEmptyRequiredFields) {
            setJobberSaveDisabled(false);
          }

          await messageApi.success(
            `You have been successfully authenticated as '` +
              result.data.name +
              `'.`
          );
        }

        setLoginGetJobberDisabled(false);
      };

      f();
    }
  }, [isGetJobberAuthCallback, pluginsList]);

  useEffect(() => {
    if (!currentOrganizationLoading) {
      setSkip(false);
    }
  }, [currentOrganizationLoading]);

  const loadingComponent = () => <Loading height={'300px'} />;
  const errorComponent = () => <div>Ooops, something went wrong.</div>;
  const [component, setComponent] = useState<JSX.Element>(loadingComponent);

  useEffect(() => {
    if (projectPluginsError || pluginsError) {
      setComponent(errorComponent);
    }
  }, [projectPluginsError, pluginsError]);

  useEffect(() => {
    if (plugins && projectPlugins) {
      const pluginsItems = plugins.map((plugin) => {
        const projectPlugin = projectPlugins.find(
          (p) => p.pluginId === plugin.id
        );

        const settingsIsOpened = openedPluginSettings.includes(plugin.id);

        if (projectPlugin) {
          let props = projectPlugin.properties;

          for (const key in plugin.properties) {
            if (props[key] === undefined) {
              props = {
                ...props,
                [key]: plugin.properties[key].defaultValue,
              };
            }
          }

          return {
            ...plugin,
            pluginId: projectPlugin.id,
            isDemoRequested: projectPlugin.isDemoRequested,
            settingsIsOpened,
            allProperties: plugin.properties,
            properties: props,
            saveDisabled: true,
            actionDisabled: false,
            status: projectPlugin.status,
            lastError: projectPlugin.lastError,
            lastErrorStackTrace: projectPlugin.lastErrorStackTrace,
          };
        } else {
          return {
            ...plugin,
            pluginId: '',
            isDemoRequested: false,
            settingsIsOpened,
            allProperties: plugin.properties,
            properties: {},
            saveDisabled: true,
            actionDisabled: false,
            status: ProjectPluginStatus.UNKNOWN,
            lastError: '',
            lastErrorStackTrace: '',
          };
        }
      });

      setPluginsList(pluginsItems);
    }
  }, [
    plugins,
    projectPlugins,
    lazyGetProjectPluginsData,
    openedPluginSettings,
  ]);

  useEffect(() => {
    if (pluginsList.length > 0) {
      setComponent(pluginsComponent());
    }
  }, [pluginsList, loginGetJobberDisabled]);

  const handleRequestDemo = async (pluginId: string) => {
    setActionBtnDisabledState(pluginId, true);

    const result = await requestDemo({
      projectId: projectId,
      pluginId: pluginId,
    });
    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      setActionBtnDisabledState(pluginId, false);
    } else {
      lazyGetProjectPluginsQuery({ id: projectId });
      await messageApi.success(`Plugin added to wishlist. Thank you.`);
    }
  };

  const handleShowHidePluginSettings = (id: string) => {
    if (disabledPluginSettings.includes(id)) return;

    if (openedPluginSettings.includes(id)) {
      hidePluginSettings(id);
    } else {
      setOpenedPluginSettings([...openedPluginSettings, id]);
    }
  };

  const hidePluginSettings = (id: string) => {
    setOpenedPluginSettings(
      openedPluginSettings.filter((openedId) => openedId !== id)
    );
  };

  // TODO: move get-jobber to separate form (and mb google-sheets too)
  const handleChangePluginSettings = (
    allVals: IProjectPluginProperties,
    id: string
  ) => {
    const plugin = pluginsList.find((p) => p.id === id);

    // TODO: need refactor
    const getJobberPlugin = plugin && plugin.name === 'get-jobber';
    const getJobberCurrentValue =
      getJobberValue ||
      (plugin?.properties as IProjectPluginProperties)?.account ||
      '';

    const googleSheetsPlugin = plugin && plugin.name === 'google_sheets';
    const googleSheetsPluginCurrentValue =
      googleAccountValue ||
      (plugin?.properties as IProjectPluginProperties)?.account ||
      '';

    const allValues =
      allVals.hasOwnProperty('account') && !allVals.account && getJobberPlugin
        ? { ...allVals, account: getJobberCurrentValue }
        : allVals.hasOwnProperty('account') &&
          !allVals.account &&
          googleSheetsPlugin
        ? { ...allVals, account: googleSheetsPluginCurrentValue }
        : allVals;

    if (plugin) {
      const allPropsEntries = Object.entries(plugin.allProperties);
      const noReadOnlyProps = allPropsEntries.filter(
        ([key, value]) => !value.readOnly
      );
      const noReadOnlyPropsKeys = noReadOnlyProps.map(([key, value]) => key);
      const allValuesEntries = Object.entries(allValues);
      const valuesForCheck = allValuesEntries.filter(([key, value]) =>
        noReadOnlyPropsKeys.includes(key)
      );
      const pluginPropsEntries = Object.entries(plugin.properties);
      const pluginPropsForCheck = pluginPropsEntries.filter(([key, value]) => {
        return noReadOnlyPropsKeys.includes(key);
      });
      const isRequiredValues = allPropsEntries.filter(
        ([key, value]) => value.isRequired
      );
      const isRequiredValuesKeys = isRequiredValues.map(([key, value]) => key);
      const isRequiredValuesEmpty = allValuesEntries.filter(
        ([key, value]) =>
          isRequiredValuesKeys.includes(key) &&
          (value === '' || value === undefined || value === null)
      );

      const valuesIsChanged =
        JSON.stringify(pluginPropsForCheck) !== JSON.stringify(valuesForCheck);

      if (valuesIsChanged && isRequiredValuesEmpty.length === 0) {
        if (getJobberPlugin) {
          setJobberSaveDisabled(false);
        }
        if (googleSheetsPlugin) {
          setGoogleSheetsSaveDisabled(false);
        }
        setPluginsList(
          pluginsList.map((p) => {
            if (p.id === id) {
              return {
                ...p,
                saveDisabled: false,
              };
            } else {
              return p;
            }
          })
        );
      } else {
        if (getJobberPlugin) {
          setJobberSaveDisabled(true);
        }
        if (googleSheetsPlugin) {
          setGoogleSheetsSaveDisabled(true);
        }
        setPluginsList(
          pluginsList.map((p) => {
            if (p.id === id) {
              return {
                ...p,
                saveDisabled: true,
              };
            } else {
              return p;
            }
          })
        );
      }
    }
  };

  const handleSubmitPluginSettings = async (
    values: IProjectPluginProperties,
    id: string,
    pluginId: string
  ) => {
    const getJobberPluginId = searchParams.get(getJobberAuthCallbackIndicator);
    const isGetJobber = id === getJobberPluginId;

    const googleSheetsPlugin = pluginsList.find(
      (p) => p.name === 'google_sheets'
    );
    const isGoogleSheets = googleSheetsPlugin && googleSheetsPlugin.id === id;

    const getJobberPluginData = pluginsList.find(
      (p) => p.name === 'get-jobber'
    );
    const getJobberCurrentValue =
      getJobberValue ||
      (getJobberPluginData?.properties as IProjectPluginProperties)?.account ||
      '';

    const googleSheetsCurrentValue =
      googleAccountValue ||
      (googleSheetsPlugin?.properties as IProjectPluginProperties)?.account ||
      '';

    let vals = isGetJobber
      ? { ...values, account: getJobberCurrentValue }
      : isGoogleSheets
      ? { ...values, account: googleSheetsCurrentValue }
      : values;

    setPluginsList(
      pluginsList.map((p) => {
        if (p.pluginId === pluginId) {
          return {
            ...p,
            saveDisabled: true,
          };
        } else {
          return p;
        }
      })
    );

    const result = await updatePluginSettings({
      projectId: projectId,
      projectPluginId: pluginId,
      data: { pluginId: id, properties: vals },
    });
    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      setPluginsList(
        pluginsList.map((p) => {
          if (p.pluginId === pluginId) {
            return {
              ...p,
              properties: vals,
              saveDisabled: false,
            };
          } else {
            return p;
          }
        })
      );
    } else {
      messageApi.success(`Plugin settings saved.`);
      // TODO: need refactor
      const pluginData = pluginsList.find((p) => p.id === id);
      if (pluginData && pluginData.name === 'get-jobber') {
        setJobberSaveDisabled(true);
      }
      if (pluginData && pluginData.name === 'google_sheets') {
        setGoogleSheetsSaveDisabled(true);
      }

      if (isGetJobber) {
        if (form) {
          form.setFieldValue('account', getJobberCurrentValue);
        }
      }
    }
    if (isGetJobber) {
      setGetJobberValue('');
    }
    if (isGoogleSheets) {
      setGoogleAccountValue('');
    }
  };

  function renderPluginForm({
    plugin,
    projectId,
    messageApi,
  }: RenderPluginFormProps) {
    if (plugin.name === PluginNames.FACEBOOK_MESSENGER) {
      return (
        <FbPluginForm
          projectId={projectId}
          plugin={plugin}
          messageApi={messageApi}
        />
      );
    } else {
      const getForm = () => {
        if (plugin.name === 'get-jobber') return form;

        return undefined;
      };
      return (
        <Form
          form={getForm()}
          className={`plugin-settings-form ${
            plugin.settingsIsOpened ? '' : 'hidden'
          }`}
          // initialValues={plugin.properties}
          key={plugin.name}
          onFinish={(values) => {
            const rehandledValues: { [key: string]: any } = {};

            for (const key in values) {
              if (Object.prototype.hasOwnProperty.call(values, key)) {
                const element = values[key];

                if (key.indexOf('{{BOOLEAN}}') > -1) {
                  const newKey = key.replace('{{BOOLEAN}}', '');
                  rehandledValues[newKey] = element === 'YES' ? true : false;
                } else {
                  rehandledValues[key] = element;
                }
              }
            }

            return handleSubmitPluginSettings(
              rehandledValues,
              plugin.id,
              plugin.pluginId
            );
          }}
          onValuesChange={(changedValues, allValues) => {
            return handleChangePluginSettings(allValues, plugin.id);
          }}
          requiredMark={false}
          layout={'vertical'}
        >
          {getAllPluginProps(plugin).map((prop, i) => {
            const key = Object.keys(prop)[0];

            const {
              displayName,
              isRequired,
              description,
              placeholder,
              defaultValue,
              values,
              type,
            } = prop[key];

            const propertyValue =
              plugin.properties[key as keyof typeof plugin.properties];

            const commonProps = {
              displayName,
              keyBase: key,
              index: i,
              isRequired,
              description,
              convertValue: propertyValue ?? defaultValue,
            };

            const placeholderValue = placeholder ?? displayName;

            switch (type) {
              case PluginPropTypes.AUTH:
                const authInputValue =
                  getJobberValue || propertyValue || defaultValue;

                const buildRedirectUrl = () => {
                  const callbackUrlBase = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                  const searchParams = new URLSearchParams(
                    window.location.search
                  );
                  searchParams.set(getJobberAuthCallbackIndicator, plugin.id);

                  const baseUrl =
                    'https://api.getjobber.com/api/oauth/authorize';
                  const clientId =
                    process.env.NODE_ENV === 'development'
                      ? '02f86657-e0d0-41c3-b5e6-41d4cf9d6a45'
                      : 'e62b9e45-048c-4a05-9ce4-e0ae50bae90b';

                  return `${baseUrl}?response_type=code&client_id=${clientId}&redirect_uri=${callbackUrlBase}?${searchParams.toString()}&state=<STATE>`;
                };

                return (
                  <Form.Item
                    label={displayName}
                    name={key}
                    key={i}
                    // Uncomment if needed
                    // rules={[
                    //   {
                    //     required: isRequired,
                    //     message: V_REQUIRED_FIELD,
                    //   },
                    // ]}
                    tooltip={description}
                    initialValue={authInputValue}
                  >
                    <div className="plugin-setting-read-only">
                      <Input
                        placeholder={placeholder ?? displayName}
                        value={authInputValue}
                        disabled={true}
                      />
                      <Button
                        onClick={() => {
                          openRedirectMessage();
                          window.location.assign(buildRedirectUrl());
                        }}
                        disabled={loginGetJobberDisabled}
                      >
                        {propertyValue || getJobberValue ? 'Relogin' : 'Login'}
                      </Button>
                      <Button
                        onClick={() => handleLogoutGetJobber(plugin.pluginId)}
                        disabled={
                          logoutGetJobberLoading ||
                          (!propertyValue && !getJobberValue)
                        }
                      >
                        Logout
                      </Button>
                    </div>
                  </Form.Item>
                );

              case PluginPropTypes.GOOGLE_AUTH:
                const googleInputValue =
                  propertyValue ?? googleAccountValue ?? defaultValue;

                return (
                  <Form.Item
                    label={displayName}
                    name={key}
                    key={i}
                    tooltip={description}
                  >
                    <div className="plugin-setting-read-only">
                      <Input
                        placeholder={placeholder ?? displayName}
                        value={googleInputValue}
                        disabled={true}
                      />
                      <GoogleLoginButton
                        onClick={() =>
                          initClient(projectId, plugin.id, plugin.pluginId)
                        }
                      />
                      {/*<Button*/}
                      {/*  onClick={() =>*/}
                      {/*    initClient(projectId, plugin.id, plugin.pluginId)*/}
                      {/*  }*/}
                      {/*>*/}
                      {/*  {plugin.properties[*/}
                      {/*    key as keyof typeof plugin.properties*/}
                      {/*  ]*/}
                      {/*    ? 'Relogin with Google'*/}
                      {/*    : 'Login with Google'}*/}
                      {/*</Button>*/}
                    </div>
                  </Form.Item>
                );

              case PluginPropTypes.BOOLEAN:
                return <BooleanFormItem {...commonProps} />;

              case PluginPropTypes.ENUM:
                return InputFactory.getEnum(
                  plugin,
                  commonProps,
                  values,
                  projectId
                );

              case PluginPropTypes.MULTILINE:
                return <MultilineFormItem {...commonProps} />;

              case PluginPropTypes.ARRAY:
                return (
                  <ArrayInputField {...commonProps} pluginName={plugin.name} />
                );

              case PluginPropTypes.KEY_VALUE_ARRAY:
                return InputFactory.getKeyValueArray(
                  plugin,
                  placeholderValue,
                  commonProps
                );

              case PluginPropTypes.PASSWORD:
                return <PasswordFormItem {...commonProps} />;

              case PluginPropTypes.INTEGER:
              case PluginPropTypes.FLOAT:
                return <NumberFormItem {...commonProps} />;

              case PluginPropTypes.PERCENTAGE:
                return <PercentageFormItem {...commonProps} />;

              case PluginPropTypes.TIME:
                return <TimeFormItem {...commonProps} />;

              default:
                return (
                  <DefaultFormItem
                    {...commonProps}
                    placeholder={placeholderValue}
                    isReadonly={prop[key].readOnly}
                    propertyValue={propertyValue}
                    pluginName={plugin.name}
                    projectId={projectId}
                  />
                );
            }
          })}
          {plugin.dataExposureLevel !== DataExposureLevel.SAFE &&
            plugin.dataExposureMessage && (
              <div>
                <Alert
                  className={'plugin-warning'}
                  message={plugin.dataExposureMessage}
                  type="warning"
                  showIcon
                  icon={<WarningOutlined className={'plugin-warning-icon'} />}
                />
              </div>
            )}
          <PluginSettingsButton plugin={plugin} />
        </Form>
      );
    }
  }

  function PluginSettingsButton({ plugin }: RenderPluginSettingsButton) {
    if (getAllPluginProps(plugin).length === 0) return null;

    return (
      <div className="plugin-setting-btn-container">
        <Form.Item className="save-changes-plugin-form-item">
          <Button
            type="primary"
            htmlType="submit"
            disabled={SaveButtonDisabledState(plugin)}
            className="btn--green"
          >
            Save Changes
          </Button>
        </Form.Item>
      </div>
    );
  }

  function SaveButtonDisabledState(plugin: IPluginItem) {
    switch (plugin.name) {
      case 'get-jobber':
        return jobberSaveDisabled;
      case 'google_sheets':
        return googleSheetsDisabled;
      default:
        return plugin.saveDisabled;
    }
  }

  const handleInstallPlugin = async (id: string) => {
    setActionBtnDisabledState(id, true);

    const result = await installPlugin({
      projectId: projectId,
      pluginId: id,
    });
    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      setActionBtnDisabledState(id, false);
    } else {
      lazyGetProjectPluginsQuery({ id: projectId });
      await messageApi.success(`Plugin has been installed.`);
    }
  };

  const handleUninstallPlugin = async (id: string, pluginId: string) => {
    setDisabledPluginSettings([...disabledPluginSettings, id]);
    setActionBtnDisabledState(id, true);
    if (openedPluginSettings.includes(id)) {
      hidePluginSettings(id);
    }

    const result = await uninstallPlugin({
      projectId: projectId,
      projectPluginId: pluginId,
      body: { pluginId: id },
    });
    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
      setActionBtnDisabledState(id, false);
      setDisabledPluginSettings(disabledPluginSettings.filter((p) => p !== id));
    } else {
      lazyGetProjectPluginsQuery({ id: projectId })
        .unwrap()
        .then(() => {
          setDisabledPluginSettings(
            disabledPluginSettings.filter((p) => p !== id)
          );
        });
      await messageApi.success(`Plugin has been uninstalled.`);
    }
  };

  const setActionBtnDisabledState = (pluginId: string, disabled: boolean) => {
    setPluginsList(
      pluginsList.map((p) => {
        if (p.id === pluginId) {
          return {
            ...p,
            actionDisabled: disabled,
          };
        } else {
          return p;
        }
      })
    );
  };

  const handleLogoutGetJobber = async (projectPluginId: string) => {
    const plugin = pluginsList.find((p) => p.pluginId === projectPluginId);
    if (!plugin) return;
    const result = await logoutGetJobberPlugin({
      projectId,
      pluginId: plugin.id,
      projectPluginId,
      userName:
        getJobberValue ||
        plugin.properties['account' as keyof typeof plugin.properties],
    });

    if ('error' in result) {
      await messageApi.error(`Logout error.`);
    } else {
      setGetJobberValue('');
      lazyGetProjectPluginsQuery({ id: projectId }).then(() => {
        setOpenedPluginSettings([...openedPluginSettings, projectPluginId]);
      });
      form.setFieldValue('account', '');

      await messageApi.success(`You have been successfully logged out.`);
    }
  };

  const openRedirectMessage = () => {
    messageApi.open({
      type: 'loading',
      content: 'Wait a second...',
      duration: 0,
      onClick: () => {
        messageApi.destroy();
      },
    });
  };

  const initClient = (
    projectId: string,
    pluginId: string,
    projectPluginId: string
  ) => {
    const scopes = [
      'https://www.googleapis.com/auth/spreadsheets',
      'https://www.googleapis.com/auth/drive.readonly',
      // 'https://www.googleapis.com/auth/drive.file'
    ];
    const client = google.accounts.oauth2.initCodeClient({
      client_id:
        '2671877163-a4fn6mt6d4of6pgfdkrgtr1dluolum9c.apps.googleusercontent.com',
      scope: scopes.join(' '),
      ux_mode: 'popup',
      callback: (response) => {
        pluginGoogleLogin({
          projectId,
          pluginId,
          projectPluginId,
          code: response.code,
          scopes,
        })
          .unwrap()
          .then((res) => {
            const pluginData = pluginsList.find((p) => p.id === pluginId);
            if (!pluginData) return;

            setGoogleAccountValue(res.name);
            setOpenedPluginSettings([...openedPluginSettings, pluginId]);
            handleChangePluginSettings(
              { ...pluginData.properties, account: res.name },
              pluginData.id
            );
            messageApi.success('Google login success.');
          })
          .catch((error) => {
            messageApi.error(error.message || 'Google login error.');
          });
      },
    });

    client.requestCode();
  };

  const pluginsComponent = () => (
    <div className="plugins-list">
      {pluginsList &&
        pluginsList
          .sort((a, b) => {
            if (!a.isDemoPlugin && a.pluginId !== '') {
              if (!b.isDemoPlugin && b.pluginId !== '') {
                return 0;
              }
              return -1;
            } else if (!b.isDemoPlugin && b.pluginId !== '') {
              return 1;
            } else if (!a.isDemoPlugin && a.pluginId === '') {
              if (!b.isDemoPlugin && b.pluginId === '') {
                return 0;
              }
              return -1;
            } else if (!b.isDemoPlugin && b.pluginId === '') {
              return 1;
            } else if (a.isDemoPlugin && a.isDemoRequested) {
              if (b.isDemoPlugin && b.isDemoRequested) {
                return 0;
              }
              return -1;
            } else if (b.isDemoPlugin && b.isDemoRequested) {
              return 1;
            } else if (a.isDemoPlugin && !a.isDemoRequested) {
              if (b.isDemoPlugin && !b.isDemoRequested) {
                return 0;
              }
              return -1;
            } else if (b.isDemoPlugin && !b.isDemoRequested) {
              return 1;
            }
            return 0;
          })
          .map((plugin, i) => (
            <div
              className={`plugins-list-item ${
                plugin.settingsIsOpened ? 'plugins-list-item--opened' : ''
              }`}
              key={i}
            >
              <div className="grid-item-container plugins-list-item__img-container">
                <img
                  src={StorageUtils.getPluginIcon(plugin.name)}
                  className="plugins-list-item__img"
                  alt={plugin.displayName}
                />
              </div>
              <div className="grid-item-container plugins-list-item__text-description">
                <div className="plugins-list-item__title-container">
                  {!plugin.isDemoPlugin && plugin.pluginId ? (
                    <Tooltip
                      placement="top"
                      title={
                        plugin.status !== ProjectPluginStatus.ERROR ? (
                          <span className={'plugin-status-title'}>
                            {plugin.status}
                          </span>
                        ) : (
                          <>
                            <span className={'plugin-status-title'}>
                              {plugin.lastError}
                            </span>
                            <br />
                            {plugin.lastErrorStackTrace}
                          </>
                        )
                      }
                      color={'#f3f3f3'}
                      overlayInnerStyle={{
                        color: '#3c4257',
                        textAlign: 'center',
                      }}
                    >
                      <div
                        className={`plugins-list-item__status plugins-list-item__status--${plugin.status}`}
                      ></div>
                    </Tooltip>
                  ) : null}
                  <div className="plugins-list-item__text-title">
                    {plugin.displayName}
                  </div>
                </div>
                {/*<div className="plugins-list-item__text-version">*/}
                {/*  {plugin.version} by {plugin.author}*/}
                {/*</div>*/}
                <div>{plugin.description}</div>
                {plugin.commands ? (
                  <div>
                    Commands:
                    <br />
                    <ul className={'plugin-commands-list'}>
                      {plugin.commands.map((command, index) => (
                        <li key={index}>{command}</li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
              <div className={'grid-item-container plugins-list-item__action'}>
                {plugin.isDemoRequested ? (
                  <Button
                    type={'primary'}
                    className={'btn--green btn--green-no-interact'}
                  >
                    Wishlisted
                  </Button>
                ) : plugin.isDemoPlugin ? (
                  <Button
                    type={'primary'}
                    onClick={() => handleRequestDemo(plugin.id)}
                    disabled={plugin.actionDisabled}
                  >
                    Add to wishlist
                  </Button>
                ) : plugin.pluginId ? (
                  <Button
                    type={'link'}
                    className={'plugin-setting-btn'}
                    disabled={disabledPluginSettings.includes(plugin.id)}
                  >
                    <SettingOutlined
                      className={'plugin-settings-icon'}
                      onClick={() => handleShowHidePluginSettings(plugin.id)}
                    />
                  </Button>
                ) : currentUserSubscription &&
                  currentUserSubscription?.currentPlan?.features
                    ?.plugin_access ? (
                  <Button
                    type={'primary'}
                    onClick={() => handleInstallPlugin(plugin.id)}
                    disabled={
                      plugin.actionDisabled ||
                      !currentUserSubscription?.currentPlan?.features
                        ?.plugin_access
                    }
                    className={'btn--green'}
                  >
                    Install
                  </Button>
                ) : (
                  <Tooltip
                    placement="top"
                    title={
                      'Upgrade Now! Unlock this feature with the Standard Plan.'
                    }
                    color={'#f3f3f3'}
                    overlayInnerStyle={{
                      color: '#3c4257',
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      type={'primary'}
                      onClick={() => handleInstallPlugin(plugin.id)}
                      disabled={
                        plugin.actionDisabled ||
                        !currentUserSubscription?.currentPlan?.features
                          ?.plugin_access
                      }
                    >
                      Install
                    </Button>
                  </Tooltip>
                )}
              </div>
              <div className="grid-item-container"></div>
              <div className="grid-item-container grid-item-container--plugin">
                {renderPluginForm({ plugin, projectId, messageApi })}
              </div>
              <div className="grid-item-container plugin-uninstall-btn-container">
                <Button
                  danger
                  onClick={() =>
                    handleUninstallPlugin(plugin.id, plugin.pluginId)
                  }
                  className={
                    plugin.settingsIsOpened ? 'plugin-uninstall-btn' : 'hidden'
                  }
                  disabled={plugin.actionDisabled}
                >
                  Uninstall
                </Button>
              </div>
              <div className="grid-item-container"></div>
            </div>
          ))}
    </div>
  );

  return (
    <div className="plugins">
      {contextHolder}
      <div className="project-setting-content-container">{component}</div>
    </div>
  );
};

interface RenderPluginFormProps {
  plugin: IPluginItem;
  projectId: string;
  messageApi: MessageInstance;
}

interface RenderPluginSettingsButton {
  plugin: IPluginItem;
}

export default Plugins;
