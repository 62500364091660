import React, { FC } from 'react';
import './insight.scss';
import { Avatar, Button } from 'antd';
import IInsight from '../../types/IInsight';
import { formatDateFromISO8601, showErrorMessage } from '../../shared/helpers';
import { CloseOutlined, ReadOutlined, UserOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../hooks/redux-hooks';
import { projectsAPI } from '../../services/projects-service';
import { MessageInstance } from 'antd/es/message/interface';
import { ConversationAiMessageItem } from '../project-settings/conversations/conversation-ai-message';
import { IConversationMessageItem } from '../../types/IConversationMessage';
import { IQAKnowledgeContent } from '../modals/teach-model-modal/teach-model-modal';
import { IngestQATypes } from '../modals/qa-ingest-modals/qa-ingest-shared-types';

interface IInsightProps extends IInsight {
  handleTeachModel: (item: IQAKnowledgeContent, reactId: string) => void;
  handleTeachModelCommand: (item: IConversationMessageItem) => void;
  messageApi: MessageInstance;
}

const Insight: FC<IInsightProps> = ({
  id,
  createAt,
  inputMessage,
  outputMessage,
  message,
  answer,
  projectId,
  isResolved,
  handleTeachModel,
  handleTeachModelCommand,
  messageApi,
}) => {
  const { currentOrganization, currentOrganizationLoading } = useAppSelector(
    (state) => state.organizationsReducer
  );
  const [closeInsight, { isLoading }] = projectsAPI.useCloseInsightMutation();

  const handleCloseInsight = async () => {
    const result = await closeInsight({ projectId, insightId: id });

    if ('error' in result) {
      await showErrorMessage(messageApi, result.error);
    } else {
      await messageApi.success('The insight has been marked as resolved.', 1);
    }
  };

  const getQaKnowledgeContent = (): IQAKnowledgeContent => {
    const question =
      inputMessage.items.map((item) => item.text || '').join('\n') || '';
    const answer =
      outputMessage.items.map((item) => item.text || '').join('\n') || '';
    return {
      question,
      answer,
      type: IngestQATypes.USER_ASKS,
      command: '',
    };
  };

  return (
    <div className="insight">
      <Button
        className={'insight-close'}
        onClick={handleCloseInsight}
        disabled={isLoading}
      >
        <CloseOutlined />
      </Button>
      <div className="insight-date">{formatDateFromISO8601(createAt)}</div>
      <div className="insight-user-message-container">
        {inputMessage.items.map((item, index) => (
          <div className="insight-user-message" key={index}>
            {item.text}
          </div>
        ))}
        <Avatar
          size={40}
          icon={<UserOutlined />}
          className={'insight-avatar'}
        />
      </div>
      <div className="insight-ai-message-container">
        {/*{process.env.REACT_APP_PARTNER_MODE === 'true' ? (*/}
        {/*  <Avatar*/}
        {/*    size={40}*/}
        {/*    icon={<UserOutlined />}*/}
        {/*    className={`insight-avatar`}*/}
        {/*    src={`https://static.${process.env.REACT_APP_DOMAIN}/static/globals/logo.svg`}*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <Avatar*/}
        {/*    size={40}*/}
        {/*    icon={<UserOutlined />}*/}
        {/*    src={`https://static.${process.env.REACT_APP_DOMAIN}/static/logo-dark.png`}*/}
        {/*    className={'insight-avatar insight-avatar--ai'}*/}
        {/*  />*/}
        {/*)}*/}
        {/*<Avatar*/}
        {/*  size={40}*/}
        {/*  icon={<UserOutlined />}*/}
        {/*  src={Logo}*/}
        {/*  className={'insight-avatar insight-avatar--ai'}*/}
        {/*/>*/}
        <ConversationAiMessageItem
          message={outputMessage}
          index={0}
          totalMessages={1}
          itemMsgChainClassName={''}
          handleTeachModel={handleTeachModelCommand}
        />
        {/*<div className="insight-ai-message">{answer}</div>*/}
      </div>
      <div className="insight-btns-container">
        <Button
          icon={<ReadOutlined />}
          onClick={() => handleTeachModel(getQaKnowledgeContent(), id)}
        >
          {/*{isResolved ? 'Edit Model' : 'Teach Model'}*/}
          Teach Model
        </Button>
      </div>
    </div>
  );
};

export default Insight;
