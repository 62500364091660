import React, { FC, useEffect } from 'react';
import './fake-widget.scss';
import ChatBody from './fake-widget-components/chat-body/chat-body';
import ChatHeader from './fake-widget-components/chat-header/chat-header';
import MainBotImage from './fake-widget-components/main-bot-image/main-bot-image';
import { IGeneratedWidgetStyle, WidgetModes } from './fake-widget-helpers';
import MessageContainer from './fake-widget-components/message-container/message-container';
import InputPanel from './fake-widget-components/input-panel/input-panel';
import IProjectStartInfo from '../../../../../../types/IProjectStartInfo';
import BrandLabel from './fake-widget-components/brand-label/brand-label';
import { UploadFile } from 'antd';
import FloatButton from './fake-widget-components/float-button/float-button';
import ImageProps from './fake-widget-components/image-props/image-props';
import { ISuperellipseMaskImageProps } from '../../../../../superellipse/superellipse-mask';
import Bubble from './fake-widget-components/bubble/bubble';

interface IFakeWidgetProps {
  projectStartInfo: IProjectStartInfo;
  generatedWidgetStyle: IGeneratedWidgetStyle;
  title: string;
  subTitle: string;
  agentName: string;
  userLabel: string;
  placeholder: string;
  image: UploadFile | null;
  allowMicrophone: boolean;
  widgetMode: WidgetModes;
  showBubble: boolean;
  bubbleMessage: string;
}

const widthDefault = 350;
const heightDefault = 550;
const miniChatHeight = 280;
const miniChatWidth = 230;

const FakeWidget: FC<IFakeWidgetProps> = ({
  projectStartInfo,
  generatedWidgetStyle,
  title,
  subTitle,
  agentName,
  userLabel,
  placeholder,
  image,
  allowMicrophone,
  widgetMode,
  showBubble,
  bubbleMessage,
}) => {
  const [width, setWidth] = React.useState<number>(widthDefault);

  const resizeWidget = () => {
    if (window.innerWidth < 360) {
      setWidth(280);
    } else if (window.innerWidth < 400) {
      setWidth(320);
    } else {
      setWidth(widthDefault);
    }
  };

  useEffect(() => {
    resizeWidget();
    window.addEventListener('resize', resizeWidget);
    return () => {
      window.removeEventListener('resize', resizeWidget);
    };
  }, []);

  const getWidth = () => {
    return widgetMode === WidgetModes.FULL ? width : miniChatWidth;
  };

  const getHeight = () => {
    return widgetMode === WidgetModes.FULL ? heightDefault : miniChatHeight;
  };

  const widgetComponent = (imageProps: ISuperellipseMaskImageProps) => {
    return (
      <div
        className={'fake-widget'}
        style={{ width: getWidth(), height: getHeight() }}
      >
        <ChatBody
          width={getWidth()}
          height={getHeight()}
          generatedWidgetStyle={generatedWidgetStyle}
          widgetMode={widgetMode}
        >
          <ChatHeader
            projectStartInfo={projectStartInfo}
            generatedWidgetStyle={generatedWidgetStyle}
            title={title}
            subTitle={subTitle}
          />
          <MainBotImage
            generatedWidgetStyle={generatedWidgetStyle}
            widgetMode={widgetMode}
            imageProps={imageProps}
          />
          {widgetMode === WidgetModes.FULL && (
            <MessageContainer
              projectStartInfo={projectStartInfo}
              generatedWidgetStyle={generatedWidgetStyle}
              agentName={agentName}
              userLabel={userLabel}
            />
          )}
          <InputPanel
            projectStartInfo={projectStartInfo}
            generatedWidgetStyle={generatedWidgetStyle}
            placeholder={placeholder}
            allowMicrophone={allowMicrophone}
          />
          <BrandLabel projectStartInfo={projectStartInfo} />
        </ChatBody>
      </div>
    );
  };

  const getMarginTop = () => {
    if (widgetMode === WidgetModes.MINI_CHAT) {
      return 80;
    }
    if (widgetMode === WidgetModes.FLOAT_BUTTON) {
      return 160;
    }
    return 0;
  };

  const getContainerClassName = () => {
    const noMarginClassName = 'fake-widget-container--no-additional-margin';

    if (widgetMode === WidgetModes.FULL || !showBubble) {
      return noMarginClassName;
    }
  };

  return (
    <div
      className={`fake-widget-container ${getContainerClassName()}`}
      style={{ width: width, marginTop: getMarginTop() }}
    >
      <ImageProps projectStartInfo={projectStartInfo} image={image}>
        {(imageProps) => (
          <>
            {widgetMode === WidgetModes.FLOAT_BUTTON ? (
              <FloatButton
                projectStartInfo={projectStartInfo}
                generatedWidgetStyle={generatedWidgetStyle}
                imageProps={imageProps}
              />
            ) : (
              widgetComponent(imageProps)
            )}
          </>
        )}
      </ImageProps>
      <Bubble
        widgetMode={widgetMode}
        showBubble={showBubble}
        bubbleMessage={bubbleMessage}
      />
    </div>
  );
};

export default FakeWidget;
