import { apiService, APITagTypes } from './api-service';
import IProject from '../types/IProject';
import IProjectKnowledge from '../types/IProjectKnowledge';
import IProjectStartInfo from '../types/IProjectStartInfo';
import ICreateProjectInfo from '../types/ICreateProjectInfo';
import IConversation, { IProjectConversation } from '../types/IConversation';
import IConversationMessage from '../types/IConversationMessage';
import IProjectInsightInfo from '../types/IProjectInsightInfo';
import IProjectOptions from '../types/IProjectOptions';
import IUpdateProjectSettingsBody from '../types/IUpdateProjectSettingsBody';
import IIngestQA from '../types/IIngestQA';
import IIngestQAContent from '../types/IIngestQAContent';
import { IQAKnowledgeContent } from '../components/modals/teach-model-modal/teach-model-modal';
import IProjectPromptsCommandsTemplates from '../types/IProjectPromptsCommandsTemplates';
import IMessageTraceInfo from '../types/IMessageTraceInfo';
import IPostTrace from '../types/IPostTrace';
import ISharedConversationData from '../types/ISharedConversationData';
import ICloneProject from '../types/ICloneProject';
import { X_ORG_ID_HEADER } from '../shared/constants';
import { IConversationsParams } from '../types/IConversationsParams';
import { ITableT } from '../types/tables-service/ITableT';
import { IEngine, IEngineOption } from '../shared/engine-options';

const getConversationsParamsString = (params: IConversationsParams) => {
  let result = '';
  for (const key in params) {
    const keyValue = params[key as keyof IConversationsParams];

    if (keyValue && keyValue.value) {
      const keyString = `${keyValue.key}=${keyValue.value}`;
      result += `${keyString}&`;
    }
  }
  return result;
};

export const projectsAPI = apiService.injectEndpoints({
  endpoints: (build) => ({
    getUserProjects: build.query<IProject[], { orgId: string }>({
      query: ({ orgId }) => ({
        url: `/projects`,
        method: 'GET',
        headers: { [X_ORG_ID_HEADER]: orgId },
      }),
      providesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    getUserProjectById: build.query<
      IProject,
      { id: string; orgId: string | undefined }
    >({
      query: ({ id, orgId }) => ({
        url: `/projects/${id}`,
        method: 'GET',
        // when need use orgId not from LS
        headers: { [X_ORG_ID_HEADER]: orgId },
      }),
    }),
    getProjectStartInfo: build.query<IProjectStartInfo, string>({
      query: (id) => ({
        url: `/projects/${id}/info`,
        method: 'GET',
      }),
    }),
    getProjectOptions: build.query<IProjectOptions, void>({
      query: (id) => ({
        url: `/projects/options`,
        method: 'GET',
      }),
    }),
    getEngineOptions: build.query<IEngine, void>({
      query: () => ({
        url: `/projects/llms`,
        method: 'GET',
      }),
    }),
    getVectorStoresOptions: build.query<string[], void>({
      query: () => ({
        url: `/projects/vector-stores`,
        method: 'GET',
      }),
    }),
    createProject: build.mutation<IProject, ICreateProjectInfo>({
      query: (data) => ({
        url: `/projects`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(data),
      }),
      invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    updateProject: build.mutation<
      void,
      { id: string; body: IUpdateProjectSettingsBody }
    >({
      query: ({ id, body }) => ({
        url: `/projects/${id}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    updateProjectImage: build.mutation<void, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `/projects/${id}/logo`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
      invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    getProjectKnowledge: build.query<
      IProjectKnowledge[],
      { projectId: string; orgId?: string | undefined }
    >({
      query: ({ projectId, orgId = undefined }) => ({
        url: `/projects/${projectId}/knowledge`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
    }),
    getProjectKnowledgeChildren: build.mutation<
      IProjectKnowledge[],
      { projectId: string; body: { parentId: string } }
    >({
      query: ({ projectId, body }) => ({
        url: `/projects/${projectId}/knowledge/children`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
    }),
    getProjectKnowledgeChildrenPaged: build.mutation<
      {
        data: IProjectKnowledge[];
        offset: number;
        limit: number;
        totalCount: number;
      },
      {
        projectId: string;
        body: { parentId: string };
        offset: number;
        limit: number;
      }
    >({
      query: ({ projectId, body, offset, limit }) => ({
        url: `/projects/${projectId}/knowledge/children-paged?offset=${offset}&limit=${limit}`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
    }),
    addProjectKnowledge: build.mutation<
      IProjectKnowledge,
      { id: string; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/projects/${id}/knowledge/file`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
      // invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    addProjectKnowledgeTable: build.mutation<
      IProjectKnowledge,
      { id: string; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/projects/${id}/knowledge/table/import`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
      // invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    getTableByKnowledgeId: build.query<
      ITableT,
      { projectId: string; knowledgeId: string }
    >({
      query: ({ projectId, knowledgeId }) => ({
        url: `/projects/${projectId}/knowledge/${knowledgeId}/table`,
        method: 'GET',
      }),
    }),
    addProjectKnowledgeText: build.mutation<
      IProjectKnowledge,
      { id: string; body: { name: string; text: string } }
    >({
      query: ({ id, body }) => ({
        url: `/projects/${id}/knowledge/text`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      // invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    addProjectKnowledgeIngestQA: build.mutation<
      IProjectKnowledge,
      {
        id: string;
        body: IIngestQA;
      }
    >({
      query: ({ id, body }) => ({
        url: `/projects/${id}/knowledge/qna`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      // invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    getProjectKnowledgeIngestQA: build.query<
      IIngestQAContent,
      {
        id: string;
        knowledgeId: string;
      }
    >({
      query: ({ id, knowledgeId }) => ({
        url: `/projects/${id}/knowledge/${knowledgeId}/qna-content`,
        method: 'GET',
      }),
    }),
    editProjectKnowledgeIngestQA: build.mutation<
      IProjectKnowledge,
      {
        id: string;
        knowledgeId: string;
        body: IIngestQA;
      }
    >({
      query: ({ id, knowledgeId, body }) => ({
        url: `/projects/${id}/knowledge/${knowledgeId}/qna-edit`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
    }),
    addProjectKnowledgeTeach: build.mutation<
      IProjectKnowledge,
      { id: string; feedbackId: string; body: { name: string; text: string } }
    >({
      query: ({ id, feedbackId, body }) => ({
        url: `/projects/${id}/knowledge/train/${feedbackId}`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_INSIGHTS],
    }),
    addProjectKnowledgeTeachNoFeedbackId: build.mutation<
      IProjectKnowledge,
      { id: string; body: { name: string; text: string } }
    >({
      query: ({ id, body }) => ({
        url: `/projects/${id}/knowledge/train`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
    }),
    teachModelQAFeedback: build.mutation<
      IProjectKnowledge,
      { id: string; feedbackId: string; body: IQAKnowledgeContent }
    >({
      query: ({ id, feedbackId, body }) => ({
        url: `/projects/${id}/knowledge/train-qna/${feedbackId}`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_INSIGHTS],
    }),
    teachModelQA: build.mutation<
      IProjectKnowledge,
      { id: string; body: IQAKnowledgeContent }
    >({
      query: ({ id, body }) => ({
        url: `/projects/${id}/knowledge/train-qna`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
    }),
    // TODO: old
    addProjectKnowledgeURL: build.mutation<
      IProject,
      { id: string; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/content/add_knowledge_from_url/${id}`,
        method: 'POST',
        headers: {
          'content-type': undefined,
        },
        body: body,
      }),
      invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    addProjectKnowledgeGithub: build.mutation<
      void,
      { projectId: string; body: { url: string; branch: string } }
    >({
      query: ({ projectId, body }) => ({
        url: `/projects/${projectId}/knowledge/github`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    addProjectKnowledgeSitemap: build.mutation<
      void,
      { projectId: string; url: string }
    >({
      query: ({ projectId, url }) => ({
        url: `/projects/${projectId}/knowledge/sitemap`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ url: url }),
      }),
      invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    addProjectKnowledgeWebsite: build.mutation<
      void,
      { projectId: string; url: string; crawlAllWebsite: boolean }
    >({
      query: ({ projectId, url, crawlAllWebsite }) => ({
        url: `/projects/${projectId}/knowledge/website`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ url: url, crawlAllWebsite: crawlAllWebsite }),
      }),
      invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    addProjectKnowledgeYoutube: build.mutation<
      void,
      { projectId: string; url: string }
    >({
      query: ({ projectId, url }) => ({
        url: `/projects/${projectId}/knowledge/youtube`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify({ url: url }),
      }),
      invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    deleteProjectKnowledge: build.mutation<
      void,
      { projectId: string; knowledgeId: string }
    >({
      query: ({ projectId, knowledgeId }) => ({
        url: `/projects/${projectId}/knowledge/${knowledgeId}/delete`,
        method: 'DELETE',
      }),
    }),
    getProjectKnowledgeInfo: build.query<
      { id: string; name: string; content: string },
      { projectId: string; knowledgeId: string }
    >({
      query: ({ projectId, knowledgeId }) => ({
        url: `/projects/${projectId}/knowledge/${knowledgeId}/content`,
        method: 'GET',
      }),
    }),
    editProjectKnowledge: build.mutation<
      void,
      {
        projectId: string;
        knowledgeId: string;
        body: { name: string; text: string };
      }
    >({
      query: ({ projectId, knowledgeId, body }) => ({
        url: `/projects/${projectId}/knowledge/${knowledgeId}/edit`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
    }),
    // saveProjectKnowledge: build.mutation<void, string>({
    //   query: (projectId) => ({
    //     url: `/projects/${projectId}/apply-changes`,
    //     method: 'POST',
    //   }),
    //   // invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    // }),
    deleteProject: build.mutation<void, string>({
      query: (id) => ({
        url: `/projects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    deleteProjectImage: build.mutation<void, string>({
      query: (id) => ({
        url: `/projects/${id}/logo`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [APITagTypes.USER_PROJECTS],
    }),
    getProjectInsights: build.query<
      IProjectInsightInfo,
      { projectId: string; orgId: string | undefined }
    >({
      query: ({ projectId, orgId }) => ({
        url: `/projects/${projectId}/reacts`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
      providesTags: () => [APITagTypes.PROJECT_INSIGHTS],
    }),
    // TODO: get mutation =/
    closeInsight: build.mutation<
      void,
      { projectId: string; insightId: string }
    >({
      query: ({ projectId, insightId }) => ({
        url: `/projects/${projectId}/resolve-react/${insightId}`,
        method: 'GET',
      }),
      invalidatesTags: () => [APITagTypes.PROJECT_INSIGHTS],
    }),
    getProjectConversations: build.query<
      IProjectConversation,
      { projectId: string; params: IConversationsParams }
    >({
      query: ({ projectId, params }) => ({
        url: `/projects/${projectId}/conversations?${getConversationsParamsString(
          params
        )}`,
        method: 'GET',
      }),
    }),
    getProjectConversationById: build.query<
      IConversation,
      { projectId: string; conversationId: string }
    >({
      query: ({ projectId, conversationId }) => ({
        url: `/projects/${projectId}/conversations/${conversationId}`,
        method: 'GET',
      }),
    }),
    getConversationMessages: build.query<
      IConversationMessage[],
      { projectId: string; conversationId: string; orgId: string | undefined }
    >({
      query: ({ projectId, conversationId, orgId }) => ({
        url: `/projects/${projectId}/conversations/${conversationId}/messages`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
    }),
    markConversationAsRead: build.mutation<
      void,
      { projectId: string; conversationId: string }
    >({
      query: ({ projectId, conversationId }) => ({
        url: `/projects/${projectId}/conversations/${conversationId}/mark-as-read`,
        method: 'POST',
      }),
    }),
    markConversationAsUnread: build.mutation<
      void,
      { projectId: string; conversationId: string }
    >({
      query: ({ projectId, conversationId }) => ({
        url: `/projects/${projectId}/conversations/${conversationId}/mark-as-unread`,
        method: 'POST',
      }),
    }),
    markConversationAsReviewed: build.mutation<
      void,
      { projectId: string; conversationId: string }
    >({
      query: ({ projectId, conversationId }) => ({
        url: `/projects/${projectId}/conversations/${conversationId}/mark-as-reviewed`,
        method: 'POST',
      }),
    }),
    markConversationAsForReview: build.mutation<
      void,
      { projectId: string; conversationId: string }
    >({
      query: ({ projectId, conversationId }) => ({
        url: `/projects/${projectId}/conversations/${conversationId}/mark-as-for-review`,
        method: 'POST',
      }),
    }),
    getUnreadConversationsCount: build.query<
      number,
      { projectId: string; orgId: string | undefined }
    >({
      query: ({ projectId, orgId }) => ({
        url: `/projects/${projectId}/conversations/unread/count`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
    }),
    getProjectPromptsCommandsTemplates: build.query<
      IProjectPromptsCommandsTemplates,
      { projectId: string; orgId: string | undefined }
    >({
      query: ({ projectId, orgId }) => ({
        url: `/projects/${projectId}/templates`,
        method: 'GET',
        headers: orgId ? { [X_ORG_ID_HEADER]: orgId } : {},
      }),
    }),
    getTraceByMessageId: build.query<
      IMessageTraceInfo,
      { projectId: string; conversationId: string; messageId: string }
    >({
      query: ({ projectId, conversationId, messageId }) => ({
        url: `/projects/${projectId}/conversations/${conversationId}/messages/${messageId}/trace`,
        method: 'GET',
      }),
    }),
    postTrace: build.mutation<void, { projectId: string; trace: IPostTrace }>({
      query: ({ projectId, trace }) => ({
        url: `/projects/${projectId}/ai/trace`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(trace),
      }),
    }),
    getSharedConversation: build.query<
      ISharedConversationData,
      { projectId: string; token: string }
    >({
      query: ({ projectId, token }) => ({
        url: `/projects/${projectId}/messages/${token}`,
        method: 'GET',
      }),
    }),
    getShareLink: build.query<string, { projectId: string; chatId: string }>({
      query: ({ projectId, chatId }) => ({
        url: `/projects/${projectId}/generate-share-link/${chatId}`,
        method: 'POST',
      }),
    }),
    downloadKnowledgeFile: build.query<
      {
        downloadUrl: string;
        contentType: string;
      },
      { projectId: string; knowledgeId: string }
    >({
      query: ({ projectId, knowledgeId }) => ({
        url: `/projects/${projectId}/knowledge/${knowledgeId}/download`,
        method: 'GET',
        responseHandler: async (response) => {
          const contentType = response.headers.get('content-type');
          return {
            downloadUrl: window.URL.createObjectURL(await response.blob()),
            contentType: contentType,
          };
        },
        cache: 'no-cache',
      }),
    }),
    cloneProject: build.mutation<IProject, ICloneProject>({
      query: (body) => ({
        url: `/projects/clone`,
        method: 'POST',
        headers: {
          'content-type': 'application/json-patch+json',
        },
        body: JSON.stringify(body),
      }),
    }),
  }),
});
