import React, { FC, useEffect, useState } from 'react';
import {
  Upload,
  UploadProps,
  Modal,
  message,
  UploadFile,
  Form,
  Input,
  Select,
  Tooltip,
} from 'antd';
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import '../../data-source-upload-modal/data-source-upload-modal.scss';
import { showErrorMessage } from '../../../../shared/helpers';
import { suuid } from '../../../../utils/suuid';
import { ITableT } from '../../../../types/tables-service/ITableT';
import { TableImportOptions } from '../../../../shared/constants';
import { whatsAppOutboundApi } from '../../../../services/what-app-outbound-service';

interface IImportTableModal {
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  campaignId: string;
  cb: () => void;
  tableData: ITableT | undefined | null;
  setImportLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const importOptions = [
  {
    value: TableImportOptions.OVERWRITE,
    label: 'Overwrite',
  },
  {
    value: TableImportOptions.MERGE,
    label: 'Merge',
  },
];

const ImportTableModal: FC<IImportTableModal> = ({
  isOpen,
  closeModal,
  projectId,
  campaignId,
  cb,
  tableData,
  setImportLoading,
}) => {
  const [form] = Form.useForm();
  const [importTable, { isLoading: importLoading }] =
    whatsAppOutboundApi.useImportTableWMutation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [showUpload, setShowUpload] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const importOption = Form.useWatch('importOptions', form);
  const mainHeader = Form.useWatch('mainHeader', form);

  useEffect(() => {
    setImportLoading(importLoading);
  }, [importLoading]);

  useEffect(() => {
    fileList.length > 0 ? setShowUpload(false) : setShowUpload(true);
  }, [fileList]);

  const { Dragger } = Upload;

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onChange(info) {
      setFileList(info.fileList);
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: () => {
      return false;
    },
    fileList,
  };

  const handleImport = async () => {
    if (!projectId) return;
    setIsLoading(true);

    const submittedFiles: string[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const formData = new FormData();

      const formValues = form.getFieldsValue();

      const schemaJson: {
        $type: string;
        hasHeader: boolean;
        separator: string;
        mergeOptions: {
          type: string;
          mainHeader?: string;
        };
      } = {
        $type: 'csv',
        hasHeader: true,
        separator: formValues.separator,
        mergeOptions: {
          type: formValues.importOptions,
        },
      };

      if (importOption === TableImportOptions.MERGE) {
        schemaJson.mergeOptions.mainHeader = formValues.mainHeader;
      }

      formData.append('file', file.originFileObj as Blob);
      formData.append('schemaJson', JSON.stringify(schemaJson));

      const uuid = suuid();

      const result = importTable({
        projectId,
        campaignId,
        importOption: formValues.importOptions,
        body: formData,
      });

      submittedFiles.push(uuid);
      // cbSubmittedFiles(submittedFiles);

      result
        .unwrap()
        .then((res) => {
          messageApi.success(`File ${file.name} has been submitted.`, 1);
          cb();
        })
        .catch((err) => {
          showErrorMessage(messageApi, err);
        })
        .finally(() => {
          submittedFiles.splice(submittedFiles.indexOf(uuid), 1);
          setImportLoading(false);
        });
    }

    setFileList([]);
    setIsLoading(false);
    setImportLoading(false);
    closeModal();
  };

  const importOptionLabel = () => (
    <div>
      Import option
      <Tooltip
        title={
          <span>
            <span style={{ fontWeight: 500 }}>Overwrite:</span> Replace the
            current table entirely with the imported data.
            <br />
            <span style={{ fontWeight: 500 }}>Merge:</span> Incorporate the new
            table data into the existing one.
          </span>
        }
        overlayStyle={{
          zIndex: 2500,
        }}
      >
        <span
          style={{
            marginTop: 3,
            marginLeft: 4,
            color: 'rgba(60, 66, 87, 0.45)',
            cursor: 'help',
          }}
        >
          <QuestionCircleOutlined />
        </span>
      </Tooltip>
    </div>
  );

  const getMainHeaderOptions = () => {
    if (!tableData) return [];
    const columns = tableData.columns;
    return columns.map((column) => ({
      label: column.name,
      value: column.name,
    }));
  };

  return (
    <Modal
      className="data-source-upload-modal"
      confirmLoading={isLoading}
      title={`Import table`}
      open={isOpen}
      onCancel={closeModal}
      cancelButtonProps={{ disabled: isLoading }}
      onOk={handleImport}
      okText="Import"
      okButtonProps={{
        style: { display: showUpload ? 'none' : 'inline-block' },
        disabled: importOption === TableImportOptions.MERGE && !mainHeader,
      }}
      centered={true}
      wrapClassName={'modal'}
      maskStyle={{ zIndex: 1100 }}
    >
      {contextHolder}
      <Form
        initialValues={{
          separator: ',',
          importOptions: TableImportOptions.OVERWRITE,
        }}
        layout="vertical"
        form={form}
        className={'import-table-modal'}
      >
        {/*<Form.Item name={`hasHeader`} valuePropName="checked">*/}
        {/*  <Checkbox>Has a header</Checkbox>*/}
        {/*</Form.Item>*/}
        <Form.Item name={`separator`} label={'Separator'}>
          <Input placeholder={','} />
        </Form.Item>
        <Form.Item name={`importOptions`} label={importOptionLabel()}>
          <Select
            disabled={!tableData}
            options={importOptions}
            dropdownStyle={{ zIndex: 2000 }}
          />
        </Form.Item>
        {tableData && importOption === TableImportOptions.MERGE && (
          <Form.Item name={`mainHeader`} label={'Main header (required)'}>
            <Select
              options={getMainHeaderOptions()}
              dropdownStyle={{ zIndex: 2000 }}
            />
          </Form.Item>
        )}
      </Form>
      <Dragger
        {...props}
        accept={'.csv'}
        className={`knowledge-dragger ${
          showUpload ? '' : 'data-source-upload-dragger-hidden'
        }`}
        maxCount={1}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
    </Modal>
  );
};

export default ImportTableModal;
