import React, { FC, useEffect, useState } from 'react';
import { Alert, Button, message, Result, Skeleton, Tooltip } from 'antd';
import { tablesApi } from '../../../../services/tables-service';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import ImportTableModal from '../../../modals/outbound/import-table-modal/import-table-modal';
import {
  getDomain,
  getErrorMessage,
  showErrorMessage,
} from '../../../../shared/helpers';
import './whats-app-outbound.scss';
import Loading from '../../../loading/loading';
import { whatsAppOutboundApi } from '../../../../services/what-app-outbound-service';
import WhatsAppSetUpModal from '../../../modals/outbound/whats-app-set-up-modal/whats-app-set-up-modal';
import WhatsAppOutboundOverview from './whats-app-outbound-overview';
import {
  CheckCircleOutlined,
  DownloadOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { FaRegStopCircle } from 'react-icons/fa';
import { FaRegCirclePlay } from 'react-icons/fa6';
import { ITableFilterValues } from '../filters/filters-helpers';
import {
  ProjectSettingsType,
  WhatsAppOutboundStatus,
} from '../../../../shared/constants';
import {
  IEditorTableColumn,
  ITableRecordsT,
  ITableRowT,
  ITableT,
} from '../../../../types/tables-service/ITableT';
import { ITableRecordErrors } from '../../../../types/TableRecordErrors';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { CampaignTypes } from '../../../../types/whats-app-outbound-service/ICreateCampaign';
import TableEditable from '../../tables/table-components/table-editable';
import DeleteColumnTModal from '../../../modals/project-settings/tables/delete-columnT-modal';
import usePagination from '../../../../hooks/usePagination';
import TablesUtils from '../../../shared/tables/tables-utils';
import { Column, SelectColumn, textEditor } from 'react-data-grid';
import useDebounce from '../../../../hooks/table-hooks/useDebounce';
import TableHelper from '../../../../shared/table-helper';
import { RouteNames } from '../../../../router/router';
import EditableHeaderCell from '../../tables/table-components/editable-header-cell';
import RecordFailedInfo from './record-failed-info';
import useUpdateTableData from '../../../../hooks/table-hooks/useUpdateTableData';
import useOnTableChange from '../../../../hooks/table-hooks/useOnTableChange';
import useSelectCell from '../../../../hooks/table-hooks/useSelectCell';
import useTableSavingDataIndicator from '../../../../hooks/table-hooks/useTableSavingDataIndicator';

export const WHATS_APP_RECORDS_PER_PAGE = 10;

const WhatsAppOutbound: FC<{
  projectId: string;
  campaignId: string;
  orgId: string | undefined;
}> = ({ projectId, campaignId, orgId }) => {
  const { subscriptionIsActive } = useAppSelector(
    (state) => state.subscriptionReducer
  );
  const {
    data: campaignData,
    isLoading: campaignLoading,
    error: campaignError,
    refetch,
  } = whatsAppOutboundApi.useGetCampaignInfoQuery({
    projectId,
    orgId,
    campaignId,
  });
  const [lazyGetCampaign] = whatsAppOutboundApi.useLazyGetCampaignInfoQuery();
  const [
    lazyGetTable,
    { data: tableData, isLoading: tableLoading, error: tableError },
  ] = tablesApi.useLazyGetTableDataTQuery();
  const [
    getTableRecords,
    {
      data: tableRecordsData,
      isLoading: tableRecordsLoading,
      error: tableRecordsError,
    },
  ] = tablesApi.useLazyGetTableRecordsTQuery();
  const [getTableRecordsByIds] = tablesApi.useLazyGetTableRecordsByIdsTQuery();
  const [validateW, { isLoading: validateLoading }] =
    whatsAppOutboundApi.useValidateWMutation();
  const [runW, { isLoading: runLoading }] =
    whatsAppOutboundApi.useRunWMutation();
  const [stopW, { isLoading: stopLoading }] =
    whatsAppOutboundApi.useStopWMutation();
  const [importModalIsOpen, setImportModalIsOpen] = useState<boolean>(false);
  const [setUpModalIsOpen, setSetUpModalIsOpen] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [runIsDisabled, setRunIsDisabled] = useState<boolean>(false);
  const [stopIsDisabled, setStopIsDisabled] = useState<boolean>(true);
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<ITableFilterValues[]>([]);
  const [disableChanging, setDisableChanging] = useState<boolean>(false);
  // const [canRun, setCanRun] = useState<boolean>(false);
  const [tableRecordsValidationErrors, setTableRecordsValidationErrors] =
    useState<ITableRecordErrors[]>([]);
  const [currentTableRecords, setCurrentTableRecords] =
    useState<ITableRecordsT | null>(null);
  const [tableName, setTableName] = useState<string | null>(null);
  const [tableColumns, setTableColumns] = useState<IEditorTableColumn[]>([]);
  const [tableRows, setTableRows] = useState<ITableRowT[]>([]);
  const [deleteColumnKey, setDeleteColumnKey] = useState<string>('');
  const [openHeaderCellMoreMenu, setOpenHeaderCellMoreMenu] =
    useState<string>('');
  const [deleteColumnLoading, setDeleteColumnLoading] = useState(false);
  const [deleteRowLoading, setDeleteRowLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(true);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const debouncedNameValue = useDebounce(tableName, 500);
  const [countError, setCountError] = useState<number>(0);
  const [
    paginationComponent,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    itemsTotalCount,
    setItemsTotalCount,
  ] = usePagination();
  const [gridRef, editingHeaderCellKey, setEditingHeaderCellKey] =
    useSelectCell(tableColumns, tableRows);
  const [updateTableData, updateTableLoading] = useUpdateTableData({
    projectId,
    tableData,
    tableRecordsData,
    tableColumns,
    tableName,
    messageApi,
    cb: () => {
      lazyGetTable({ projectId, tableId: tableData?.id || '' });
    },
  });
  const [
    onAddColumn,
    onAddRow,
    onRowsChange,
    addColumnLoading,
    addRowLoading,
    upsertRecordsLoading,
    handleHeaderChange,
  ] = useOnTableChange({
    gridRef,
    projectId,
    tableData,
    tableColumns,
    setTableColumns,
    tableRows,
    setTableRows,
    onAddColumnCb: (projectId: string, tableId: string) =>
      onAddColumnCb(projectId, tableId),
    pagination: {
      currentPage,
      setCurrentPage,
      itemsPerPage,
      itemsTotalCount,
    },
    getRecords: (offset: number, limit: number) =>
      hookGetRecords(offset, limit),
    messageApi,
  });
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (tableData) {
      const isReadOnlyValue =
        tableData.isReadOnly ||
        campaignData?.status !== WhatsAppOutboundStatus.READY;
      setIsReadOnly(isReadOnlyValue);
    }
  }, [tableData, campaignData]);

  async function onAddColumnCb(projectId: string, tableId: string) {
    await lazyGetTable({ projectId, tableId });
  }

  async function hookGetRecords(offset: number, limit: number) {
    // await getRecords((currentPage - 1) * itemsPerPage, itemsPerPage);
    await getRecords(offset, limit);
  }

  const [savingData] = useTableSavingDataIndicator({
    startLoading,
    updateTableLoading,
    upsertRecordsLoading,
    addColumnLoading,
    addRowLoading,
    deleteColumnLoading,
    deleteRowLoading,
  });

  function getColumnsT(
    tableData: ITableT,
    isBlocked: boolean = false
  ): Column<{ [key: string]: string }>[] {
    if (!tableData) return [];

    const columns: Column<{ [key: string]: string }>[] = [SelectColumn];
    const linkKey = TableHelper.GetKeyByName(tableData, 'conversation link');

    tableData.columns.forEach((col) => {
      const whatsAppStatusColName = 'whatsAppStatus';

      if (['whatsAppSid', 'leadSyncAt'].includes(col.name)) {
        return;
      }

      let width: string | number = 'auto';

      if (currentTableRecords) {
        width = TablesUtils.getColWidth(col, currentTableRecords);
      } else {
        const currentCol = tableColumns.find((c) => c.key === col.key);
        if (currentCol) {
          width = currentCol.width || '';
        }
      }

      if (col.key != linkKey) {
        columns.push({
          ...col,
          width: width,
          resizable: true,
          headerCellClass: `colKey--${col.key}`,
          renderEditCell:
            col.name === whatsAppStatusColName || isBlocked
              ? undefined
              : textEditor,
          renderCell: ({ row }) => {
            const isErrorCell = tableRecordsValidationErrors.find(
              (item) => Object.keys(item)[0] === row.key
            );
            let tooltip = '';
            if (isErrorCell) {
              const errors = isErrorCell[row.key];
              const cellErrors = errors.filter(
                (item) => Object.keys(item)[0] === col.key
              );
              if (cellErrors) {
                tooltip = cellErrors
                  .map((item) => Object.values(item)[0])
                  .join('\n');
              }
            }
            if (tooltip) {
              return (
                <Tooltip
                  placement="top"
                  title={tooltip}
                  color={'#f3f3f3'}
                  overlayInnerStyle={{ color: '#3c4257' }}
                  overlayStyle={{ zIndex: 3000 }}
                >
                  <div
                    style={{
                      backgroundColor: '#ffa8a8',
                      marginRight: -8,
                      marginLeft: -8,
                      paddingRight: 8,
                      paddingLeft: 8,
                      height: '100%',
                    }}
                  >
                    {row[col.key]}
                  </div>
                </Tooltip>
              );
            }
            return row[col.key];
          },
          renderHeaderCell: (props) => {
            return (
              <EditableHeaderCell
                isReadOnly={isReadOnly}
                column={props.column}
                onHeaderChange={(newName) =>
                  handleHeaderChange(props.column.idx, newName)
                }
                onDeleteColumn={onDeleteColumn}
                setTableColumns={setTableColumns}
                setFilterValues={setFilterValues}
                editingHeaderCellKey={editingHeaderCellKey}
                setEditingHeaderCellKey={setEditingHeaderCellKey}
                openHeaderCellMoreMenu={openHeaderCellMoreMenu}
                setOpenHeaderCellMoreMenu={setOpenHeaderCellMoreMenu}
              />
            );
          },
        });
      } else {
        columns.push({
          ...col,
          width: 135,
          headerCellClass: `colKey--${col.key}`,
          renderCell: ({ row }) => {
            const rowLinkKey = row[linkKey];

            const handleShareClick = () => {
              if (rowLinkKey) {
                const domain =
                  process.env.NODE_ENV === 'development'
                    ? 'http://localhost:3000'
                    : 'https://dashboard.' + getDomain();
                window.open(
                  `${domain}${RouteNames.USER_PROJECTS}/${orgId}${RouteNames.PROJECT_SETTINGS_TAB_NAME}/${projectId}/${ProjectSettingsType.CONVERSATIONS}/${row[linkKey]}`,
                  '_blank'
                );
              }
            };

            return rowLinkKey ? (
              <Button
                onClick={handleShareClick}
                onAuxClick={handleShareClick}
                style={{ border: 'none', color: '#0000EE' }}
              >
                conversation
              </Button>
            ) : null;
          },
        });
      }
    });

    return columns;
  }

  useEffect(() => {
    if (tableData) {
      setTableColumns(getColumnsT(tableData, isReadOnly));
    }
  }, [
    tableData,
    editingHeaderCellKey,
    openHeaderCellMoreMenu,
    tableRecordsValidationErrors,
    isReadOnly,
  ]);

  useEffect(() => {
    if (currentTableRecords && tableData) {
      setTableRows(TablesUtils.getTableRows(currentTableRecords, tableData));
    }
  }, [currentTableRecords, tableData]);

  useEffect(() => {
    refetch();
  }, [campaignData?.fromId, setUpModalIsOpen]);

  useEffect(() => {
    if (campaignData && campaignData.tableId) {
      lazyGetTable({ projectId, tableId: campaignData.tableId })
        .then(() => {
          setAllowUpdate(true);
        })
        .finally(() => setStartLoading(false));
    }

    if (campaignData) {
      if (campaignData.status === WhatsAppOutboundStatus.READY) {
        setDisableChanging(false);
      } else {
        setDisableChanging(true);
      }

      if (campaignData.type === CampaignTypes.FIXED) {
        setStartLoading(false);
      }

      if (campaignData.analytics) {
        setCountError(campaignData.analytics.failed);
      }
    }
  }, [campaignData]);

  useEffect(() => {
    if (tableData) {
      lazyGetCampaign({ projectId, orgId, campaignId });
    }
  }, [tableData]);

  useEffect(() => {
    if (debouncedNameValue) {
      updateTableData();
    }
  }, [debouncedNameValue]);

  useEffect(() => {
    if (allowUpdate) {
      updateTableData();
    }
  }, [tableColumns]);

  const getRecords = async (
    offset: number = 0,
    limit: number = WHATS_APP_RECORDS_PER_PAGE
  ) => {
    if (!tableData) return;
    const res = await getTableRecords({
      projectId,
      id: tableData.id,
      params: { offset: offset, limit: limit, filterValues },
    });
    if (res.data) {
      setCurrentTableRecords(res.data);
      setItemsTotalCount(res.data.totalCount);
      setTableRecordsValidationErrors([]);
      return res.data;
    }
  };

  useEffect(() => {
    getRecords((currentPage - 1) * itemsPerPage, itemsPerPage);
  }, [currentPage, itemsPerPage, tableData, filterValues]);

  const onDeleteColumn = (columnKey: string) => {
    setDeleteColumnKey(columnKey);
  };

  const deleteColumnCb = () => {
    if (!tableData) return;
    setDeleteLoading(true);
    lazyGetTable({ projectId, tableId: tableData.id }).then(() => {
      setDeleteLoading(false);
    });
  };

  const resetAllFilters = () => {
    setFilterValues([]);
  };

  const renderErrorFilterInfo = () => {
    const message = () => (
      <span className={'records-selected-info-btn'} onClick={resetAllFilters}>
        Reset error records filter and show all entries
      </span>
    );

    return (
      <Alert
        message={message()}
        type="info"
        style={{ textAlign: 'center', marginTop: 24, marginBottom: 24 }}
      />
    );
  };

  const renderImportAction = () => (
    <Button
      icon={<DownloadOutlined />}
      disabled={!subscriptionIsActive || tableLoading || disableChanging}
      onClick={() => setImportModalIsOpen(true)}
    >
      Import
    </Button>
  );

  const renderComponent = () => {
    if (
      tableLoading ||
      tableRecordsLoading ||
      importLoading ||
      campaignLoading ||
      startLoading
    ) {
      return <Loading height={'300px'} />;
    }

    if (campaignError) {
      return <Result status="error" title="Can't load campaign data." />;
    }

    if (campaignData && !campaignData.tableId) {
      return (
        <div className={'whats-app-outbound-no-table'}>
          No table.{' '}
          <span
            className={'records-selected-info-btn whats-app-import-inline-btn'}
            onClick={() => setImportModalIsOpen(true)}
          >
            Import to start.
          </span>
        </div>
      );
    }

    if (tableError || tableRecordsError) {
      let message = 'Something went wrong.';

      if (tableError) {
        message = getErrorMessage(tableError);
      }

      if (tableRecordsError) {
        message = getErrorMessage(tableRecordsError);
      }

      return (
        <Result
          status="error"
          title={"Can't load table data."}
          subTitle={message}
        />
      );
    }

    if (tableData && currentTableRecords) {
      return (
        <>
          {actionsHeader()}
          <div>
            <WhatsAppOutboundOverview statusData={campaignData?.analytics} />
            {deleteLoading ? (
              <Skeleton active />
            ) : (
              <>
                <RecordFailedInfo
                  countError={countError}
                  tableId={tableData.id}
                />
                {tableRecordsValidationErrors.length > 0
                  ? renderErrorFilterInfo()
                  : null}
                <TableEditable
                  projectId={projectId}
                  tableName={tableName}
                  setTableName={setTableName}
                  tableData={tableData}
                  isReadOnly={isReadOnly}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  itemsTotalCount={itemsTotalCount}
                  tableColumns={tableColumns}
                  onAddColumn={onAddColumn}
                  addColumnLoading={addColumnLoading}
                  tableRows={tableRows}
                  onAddRow={onAddRow}
                  addRowLoading={addRowLoading}
                  onRowsChange={onRowsChange}
                  tableRecordsData={currentTableRecords}
                  getRecordsCb={() => {
                    return getRecords(
                      (currentPage - 1) * itemsPerPage,
                      itemsPerPage
                    );
                  }}
                  messageApi={messageApi}
                  editingHeaderCellKey={editingHeaderCellKey}
                  setEditingHeaderCellKey={setEditingHeaderCellKey}
                  gridRef={gridRef}
                  savingData={savingData}
                  setDeleteRowLoading={setDeleteRowLoading}
                  renderImportAction={renderImportAction}
                />
                {paginationComponent}
                <DeleteColumnTModal
                  projectId={projectId}
                  tableData={tableData}
                  deleteColumnKey={deleteColumnKey}
                  setDeleteColumnKey={setDeleteColumnKey}
                  setTableColumns={setTableColumns}
                  setTableRows={setTableRows}
                  setDeleteColumnLoading={setDeleteColumnLoading}
                  deleteColumnCb={deleteColumnCb}
                />
              </>
            )}
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    const f = async () => {
      if (tableRecordsValidationErrors.length) {
        const recordsIds = tableRecordsValidationErrors.map(
          (record) => Object.keys(record)[0]
        );
        const res = await getTableRecordsByIds({
          projectId,
          tableId: tableData?.id || '',
          recordsIds,
        });
        if ('error' in res) {
          getRecords((currentPage - 1) * itemsPerPage, itemsPerPage);
        } else if (res.data) {
          setCurrentTableRecords({
            data: res.data,
            offset: 0,
            limit: 100,
            totalCount: res.data.length,
            totalPages: 1,
            currentPage: 1,
          });
        }
      }
    };

    f();
  }, [tableRecordsValidationErrors]);

  const handleInvalidRecords = (error: unknown) => {
    const err = error as {
      error: {
        data: { message: string; errors: ITableRecordErrors[] };
        status: number;
      };
    };

    if (
      err.error.status === 400 &&
      err.error.data &&
      err.error.data.errors &&
      err.error.data.errors.length
    ) {
      setTableRecordsValidationErrors(err.error.data.errors);
    }
  };

  const handleValidate = async () => {
    setFilterValues([]);
    setTableRecordsValidationErrors([]);

    const res = await validateW({ projectId, campaignId });
    if ('error' in res) {
      handleInvalidRecords(res);
      showTableValidationErrorMessage(res.error);
    } else {
      messageApi.success('Data is valid.');
    }
  };

  const showTableValidationErrorMessage = (
    error: FetchBaseQueryError | SerializedError
  ) => {
    if (
      'data' in error &&
      error.data &&
      'message' in (error.data as { message: string }) &&
      (error.data as { message: string }).message
    ) {
      messageApi.error((error.data as { message: string }).message);
    } else {
      showErrorMessage(messageApi, error);
    }
  };

  useEffect(() => {
    if (!campaignData) {
      return;
    }

    if (
      campaignData.type === CampaignTypes.CONTINOUS &&
      campaignData.status === WhatsAppOutboundStatus.RUNNING
    ) {
      setStopIsDisabled(false);
    }
  }, [campaignData]);

  const handleRun = async () => {
    if (!tableData) return;
    setRunIsDisabled(true);
    setStopIsDisabled(false);

    window.setTimeout(() => {
      if (campaignData?.type === CampaignTypes.FIXED) {
        setStopIsDisabled(true);
      }

      setRunIsDisabled(false);
    }, 60000);

    const res = await runW({ projectId, campaignId });
    if ('error' in res) {
      setRunIsDisabled(false);
      setStopIsDisabled(true);
      handleInvalidRecords(res);
      showTableValidationErrorMessage(res.error);
    } else {
      // TODO: what?
      setTableRecordsValidationErrors([]);
      messageApi.success('Running.');
      lazyGetCampaign({ projectId, orgId, campaignId });
    }
  };

  const handleStop = async () => {
    setStopIsDisabled(true);
    const res = await stopW({ projectId, campaignId });
    if ('error' in res) {
      setRunIsDisabled(true);
      setStopIsDisabled(false);
      await showErrorMessage(messageApi, res.error);
    } else {
      // TODO: what?
      messageApi.success('Stopped.');
      setRunIsDisabled(false);
    }
  };

  const actionsHeader = () => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 16,
      }}
    >
      <Button
        icon={<SettingOutlined />}
        disabled={!subscriptionIsActive || !tableRecordsData}
        onClick={() => setSetUpModalIsOpen(true)}
      >
        Set up
      </Button>
      <Button
        icon={<CheckCircleOutlined />}
        disabled={
          !subscriptionIsActive ||
          !tableRecordsData ||
          validateLoading ||
          campaignData?.status !== WhatsAppOutboundStatus.READY
        }
        loading={validateLoading}
        onClick={handleValidate}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        Validate
      </Button>
      <Button
        disabled={
          !subscriptionIsActive ||
          !tableRecordsData ||
          runIsDisabled ||
          runLoading ||
          stopLoading ||
          disableChanging
          //  || !canRun
        }
        onClick={handleRun}
        className={'whats-app-outbound-button'}
      >
        <FaRegCirclePlay className={'whats-app-outbound-button--run'} />
        Run
      </Button>
      <Button
        disabled={
          !subscriptionIsActive ||
          !tableRecordsData ||
          stopIsDisabled ||
          runLoading ||
          stopLoading
        }
        onClick={handleStop}
        className={'whats-app-outbound-button'}
      >
        <FaRegStopCircle className={'whats-app-outbound-button--stop'} />
        Stop
      </Button>
    </div>
  );

  const importTableCb = () => {
    lazyGetCampaign({ projectId, orgId, campaignId });
  };

  return (
    <div>
      {contextHolder}
      {campaignData && (
        <div style={{ fontSize: 24, marginBottom: 24 }}>
          Campaign <span style={{ fontWeight: 500 }}>{campaignData.name}</span>
        </div>
      )}
      {renderComponent()}
      <ImportTableModal
        isOpen={importModalIsOpen}
        closeModal={() => setImportModalIsOpen(false)}
        projectId={projectId}
        campaignId={campaignId}
        cb={() => importTableCb()}
        tableData={tableData}
        setImportLoading={setImportLoading}
      />

      {campaignData?.tableId && (
        <WhatsAppSetUpModal
          isOpen={setUpModalIsOpen}
          setIsOpen={setSetUpModalIsOpen}
          projectId={projectId}
          campaignId={campaignId}
          tableId={campaignData?.tableId || ''}
          messageApi={messageApi}
          disableChanging={disableChanging}
        />
      )}
    </div>
  );
};

export default WhatsAppOutbound;
